import Faq from "../Faq/Faq";
import classes from "./Gallery.module.css";
import Image1 from "../../assets/gallery/_MG_9874.JPG";
import Image2 from "../../assets/gallery/DSC_0413.JPG";
import Image3 from "../../assets/gallery/IMG_0332.JPG";
import Image4 from "../../assets/gallery/IMG_1904.JPG";
import Image5 from "../../assets/gallery/IMG_1969.JPG";
import Image6 from "../../assets/gallery/IMG_1974.JPG";
import Image7 from "../../assets/gallery/IMG_2070.JPG";
import Image8 from "../../assets/gallery/IMG_2114.JPG";
import Image9 from "../../assets/gallery/IMG_2126.JPG";
import Image10  from "../../assets/gallery/IMG_2224.JPG";
import Image11  from "../../assets/gallery/IMG_2320.JPG";

const Gallery = () => {


  const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,

  ];


  return (
    <>
      <div className={classes.events_section}>
        <h1 className={classes.heading}>Gallery</h1>
        <div className={classes.responsivegallery}>
          {images.map((src, index) => (
            <div key={index} className={classes.responsivegalleryitem}>
              <img src={src} alt={`Gallery image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>




    </>
  );
};

export default Gallery;

import Faq from "../Faq/Faq";
import classes from "./Teams.module.css";
import EventCard from "../common/EventCard/EventCard";
import { eventsData } from "../../assets/eventsData";
import Button from "../common/Button/Button";
import RoboRace from "../../assets/Events/RoboRace.png";
import Sahil from "../../assets/teams/sahil-sharma.jpg";
import Subhash from "../../assets/teams/shubhash-sharma.jpg";
import Aniket from "../../assets/teams/aniket-adarsh.jpg";
import Satendra from "../../assets/teams/satendra.jpg";
import Rohit from "../../assets/teams/rohit-mishra.jpg";
import Vaibhaw from "../../assets/teams/vaibhaw-jaishwal.jpg";
import abhishek from "../../assets/teams/Abhishek-Sir.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\Abhishek-Sir.jpg
import sandeep from "../../assets/teams/sandeep-kumar.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\sandeep-kumar.jpg
import kshitiz  from "../../assets/teams/Kshitij-Jain.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\Kshitij Jain.jpg
import tripti from "../../assets/teams/Tripti-mam.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\Tripti-mam.jpg
import sanjeev from "../../assets/teams/Sanjeev-Sir.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\Sanjeev-Sir.jpg
import tauseef from "../../assets/teams/Tauseef-Iqbal.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\Tauseef-Iqbal.jpg
import prakhar from "../../assets/teams/Prakhar-Goel.jpg"; //D:\Project\utkrisht-main\utkrisht-main\client\src\assets\teams\Prakhar-Goel.jpg
import ankriti from "../../assets/teams/ankriti-karn.jpg"; //D:\Project\utkrisht-main\client\src\assets\teams\ankriti-karn.jpg
import tanisha from "../../assets/teams/tanisha-rawat.jpg"; //D:\Project\utkrisht-main\client\src\assets\teams\tanisha-rawat.jpg
import abhinav from "../../assets/teams/abhinav-singhal.jpg"; //D:\Project\utkrisht-main\client\src\assets\teams\abhinav-singhal.jpg
import akanksha from "../../assets/teams/akanksh-chauhan.jpeg"; //D:\Project\utkrisht-main\client\src\assets\teams\akanksh-chauhan.jpeg
import yashika from "../../assets/teams/yashika-pant.jpg"; //D:\Project\utkrisht-main\client\src\assets\teams\yashika-pant.jpg
import rishav from "../../assets/teams/rishabh-kumar.jpg"; //D:\Project\utkrisht-main\client\src\assets\teams\rishabh-kumar.jpg
import akriti from "../../assets/teams/akriti-kumari.jpg"; //D:\Project\utkrisht-main\client\src\assets\teams\akriti-kumari.jpg
import prachi from "../../assets/teams/prachi-joshi.jpg";  //client\src\assets\teams\prachi-joshi.jpg
import oshin from "../../assets/teams/oshin-prakash.jpg";  //D:\Project\utkrisht-main\client\src\assets\teams\oshin-prakash.jpg
import Sumesh from "../../assets/teams/sumesh-ranjan.jpg";

const Teams = () => {
  const rohitlinkedin = "https://www.linkedin.com/in/rohitmishra-/";
  const sahillinkedin = "https://www.linkedin.com/in/sahilkgupta/";
  const subhashlinkedin = "https://www.linkedin.com/in/subhhash/";
  const aniketlinkedin = "https://www.linkedin.com/in/aniket-adarsh/";
  const sumeshlinkedin = "https://www.linkedin.com/in/sumesh-ranjan-665449275/";
  const akritilinkedin = "https://www.linkedin.com/in/akriti-kumari-26a24b2b8/";
  const ankritilinkedin = "https://www.linkedin.com/in/ankriti-karn-b35051282/";
  const tanishalinkedin = "https://www.linkedin.com/in/tanisha-rawat-a954732a2/";
  const yashikalinkedin = "https://www.linkedin.com/in/yashika-pant-422b0a277/";
  const satyamlinkedin = "https://www.linkedin.com/in/satyam-bhardwaj-4866372a8/";
  const vaibhavlinkedin = "https://www.linkedin.com/in/vaibhav-jaiswal-20770b219/";
  const mustafalinkedin = "https://www.linkedin.com/in/ziyaul-mustafa-607a04287/";
  const oshinlinkedin = "https://www.linkedin.com/in/oshin-prakash-397b75290/";
  const abhinavlinkedin = "https://www.linkedin.com/in/abhinav-singhal-069a16260/";
  const akankshalinkedin = "https://www.linkedin.com/in/akanksha-chauhan-5095b7266/";
  const rishavlinkedin = "https://www.linkedin.com/in/rishabh-kumar-mandal-2470b0272/";
  const prachilinkedin = "https://www.linkedin.com/in/prachi-joshi-7957172a7/";



  return (
    <>

      <div className={classes.events_section}>

        {/* WIE Society Section */}
        <h1 className={classes.heading}>Faculty Core Team</h1>
        <div className={classes.show_category}>
          {/* Add CS Society content similarly */}
          {/* Tripti Mam */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={tripti}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Dr Tripti Khanduri</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 9149186479</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={akritilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>

          {/* Sanjeev Solenki */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={sanjeev}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Dr Sanjeev Solanki</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 7891124600</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={oshinlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>

          {/* Tauseef Iqbal  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={tauseef}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Mr. Tausheef Iqbal</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 8899414007</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={prachilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>
          {/* Sandeep Kumar  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={sandeep}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Mr Sandeep Kumar</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 9457272262</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={prachilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>
          {/* Mr. Abhishek Chakraborty  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={abhishek}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Mr. Abhishek Chakraborty</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 9411779676</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={prachilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>
          {/* Mr. Kshitij Jain  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={kshitiz}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Mr. Kshitij Jain</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 8375052135</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={prachilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>
          {/* Mr. Prakhar Goel  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={prakhar}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Mr. Prakhar Goel</h3>
              <h4 className={classes.subtag}>Faculty Co-ordinator</h4>
              <h3 className={classes.number}>+91 9994758886</h3>
              <div className={classes.view_more_btn}>
                {/* <a
                  href={prachilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "} */}
              </div>
            </div>
          </div>
        </div>

        <h1 className={classes.heading}>Student Core Team</h1>
        {/* <h3 className={classes.subheading}>AS</h3> */}

        {/* AGRICULTURE Section  */}
        <div className={classes.show_category}>

          {/* Sahil K Gupta */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Sahil}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Sahil K. Gupta</h3>
              <h4 className={classes.subtag}>Chair, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 7070141664</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={sahillinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Subhash Sharma  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Subhash}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Shubhash Sharma</h3>
              <h4 className={classes.subtag}>Vice Chair, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 8279926831</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={subhashlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Aniket Adarsh  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Aniket}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Aniket Adarsh</h3>
              <h4 className={classes.subtag}>Secretary, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 8936010166</h3>

              <div className={classes.view_more_btn}>
                <a
                  href={aniketlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Satendra Singh  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Satendra}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Satendra Singh</h3>
              <h4 className={classes.subtag}>Tresurer, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 9565483848</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={rohitlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* ROhit Mishra  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Rohit}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Rohit Mishra</h3>
              <h4 className={classes.subtag}>Webmaster, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 9123155216</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={rohitlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Vaibhaw Jaishwal  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Vaibhaw}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Vaibhav Jaiswal</h3>
              <h4 className={classes.subtag}>Designer, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 9369156650</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={vaibhavlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Sumesh Ranjan  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={Sumesh}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Sumesh Ranjan</h3>
              <h4 className={classes.subtag}>Media Head, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 6202769571</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={sumeshlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>


        </div>

        {/* WIE Society Section */}
        <h1 className={classes.heading}>WIE Society</h1>
        <div className={classes.show_category}>
          {/* Add CS Society content similarly */}
          {/* Akriti Kumari */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={akriti}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Akriti Kumari</h3>
              <h4 className={classes.subtag}>WIE Chair, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 7668727470</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={akritilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Oshin Prakash */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={oshin}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Oshin Prakash</h3>
              <h4 className={classes.subtag}>Secretary, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 9065811448</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={oshinlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Prachi Joshi  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={prachi}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Prachi Joshi</h3>
              <h4 className={classes.subtag}>Executive Member, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 6396244429</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={prachilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        {/* CS Society Section */}
        <h1 className={classes.heading}>CS Society</h1>
        <div className={classes.show_category}>
          {/* Add CS Society content similarly */}
          {/* Ankriti Karn */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={ankriti}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Ankriti Karn</h3>
              <h4 className={classes.subtag}>CS Chair, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 7668727470</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={ankritilinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Tanisha Rawat  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={tanisha}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Tanisha Rawat</h3>
              <h4 className={classes.subtag}>Secretary, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 8439936709</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={tanishalinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Abhinav Singhal  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={abhinav}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Abhinav Singhal</h3>
              <h4 className={classes.subtag}>Executive Member, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 7070141664</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={abhinavlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        {/* PES Society Section */}
        <h1 className={classes.heading}>PES Society</h1>
        <div className={classes.show_category}>
          {/* Add PES Society content similarly */}
          {/* Akanksha Chauhan */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={akanksha}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Akanksha Chauhan</h3>
              <h4 className={classes.subtag}>PES Chair, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 6396832726</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={akankshalinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Yashika Pant  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={yashika}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Yashika Pant</h3>
              <h4 className={classes.subtag}>Secretary, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 7451021444</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={yashikalinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>

          {/* Rishav kumar Mandal  */}
          <div className={classes.card}>
            <div className={classes.card2}>
              <h3 className={classes.event_name}>{ }</h3>
              <div className={classes.img_container}>
                <img
                  className={classes.event_image}
                  src={rishav}
                  alt="eventImage"
                />
              </div>
              <h3 className={classes.subheading}>Rishav Kumar Mandal</h3>
              <h4 className={classes.subtag}>Executive Member, IEEE SB Tula's Institute</h4>
              <h3 className={classes.number}>+91 7983551280</h3>
              <div className={classes.view_more_btn}>
                <a
                  href={rishavlinkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footer_a_link}
                >
                  <img
                    className={classes.footer_social_link}
                    src="https://img.icons8.com/color/48/ffffff/linkedin-circled--v1.png"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Teams;

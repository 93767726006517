import Image1 from "./Event/1.jpg";
import Image2 from "./Event/2.jpg";
import Image3 from "./Event/3.jpg";
import Image4 from "./Event/4.jpg";
import Image5 from "./Event/5.jpg";
import Image6 from "./Event/6.jpg";
import Image7 from "./Event/7.jpg";
import Image8 from "./Event/8.jpg";
import Image9 from "./Event/9.jpg";
import Image10 from "./Event/10.jpg";
import Image11 from "./Event/11.jpg";
import Image12 from "./Event/12.jpg";
import Image13 from "./Event/13.jpg";
import Image14 from "./Event/14.jpg";
import Image15 from "./Event/15.jpg";
import Image16 from "./Event/16.jpg";
import Image17 from "./Event/17.jpg";
import Image18 from "./Event/18.jpg";
import Image19 from "./Event/19.jpg";
import Image20 from "./Event/20.jpg";
import Image21 from "./Event/21.jpg";
import Image22 from "./Event/22.jpg";
import Image23 from "./Event/23.jpg";
import Image24 from "./Event/24.jpg";
import Image25 from "./Event/25.jpg";
import Image26 from "./Event/26.jpg";
import Image27 from "./Event/27.jpg";
import Image28 from "./Event/28.jpg";
import Image29 from "./Event/29.jpg";
import Image30 from "./Event/30.jpg";
import Image31 from "./Event/31.jpg";
import Image32 from "./Event/32.jpg";
import Image33 from "./Event/33.jpg";
import Image34 from "./Event/34.jpg";
import Image35 from "./Event/35.jpg";
import Image36 from "./Event/36.jpg";
import Image37 from "./Event/37.jpg";
import Image38 from "./Event/38.jpg";
import Image39 from "./Event/39.jpg";
import Image40 from "./Event/40.jpg";
import Image41 from "./Event/41.jpg";
import Image42 from "./Event/42.jpg";
import Image43 from "./Event/43.jpg";
import Image44 from "./Event/44.jpg";
import Image45 from "./Event/45.jpg";
import Image46 from "./Event/46.jpg";
import Image47 from "./Event/47.jpg";
import Image48 from "./Event/48.jpg";
import Image49 from "./Event/49.jpg";
import Image50 from "./Event/50.jpg";
import Image51 from "./Event/51.jpg";
import Image52 from "./Event/52.jpg";
import Image53 from "./Event/53.jpg";
import Image54 from "./Event/54.jpg";
import Image55 from "./Event/55.jpg";
import Image56 from "./Event/56.jpg";
import Image57 from "./Event/57.jpg";
import Image58 from "./Event/58.jpg";
import Image59 from "./Event/59.jpg";
// import Image60 from "./Event/60.jpg";
// import Image61 from "./Event/61.jpg";

export const eventsData = [
  {
    id: 1,
    image: Image56,
    slug: "seed-seedling-&-crop-disease-identification-competition",
    category: "AGRI",
    name: "Identification of Seeds, seedlings and crop diseases of various agricultural crops",
    description:
      "Seed, Seedling & Crop Disease identification competition (Dept Specific) with workshop",
    rules: [
      "General Rules:",
      "Eligibility: The competition is open to agricultural professionals.",
      "Identification Rules",
      "Seeds Identification: Participants will be shown a set of [ 15 ] seeds, and they must identify the crop species, variety, and characteristics.",
      "Plants Identification: Participants will be shown a set of [ 10 ] plants, and they must identify the crop species, growth stage, and characteristics.",
      "Diseases Identification: Participants will be shown a set of [ 8] diseased crop samples, and they must identify the disease, causal agent, and symptoms.",
    ],
    prizes: [
      "Certificate Of Participation to All",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Shivendra Mishra - +91 7987084939",
      "Dr. Mukesh - +91 7088157321",
      "Student Coordinators",
      "Abhijeet Kashyap - +91 9297633245",
      "Jyoti - +91 7970977459",
    ],
    location: "Tula’s Institute, E404",
    date: "29 November 2024, 2:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 2,
    image: Image57,
    slug: "know-your-tools",
    category: "AGRI",
    name: "KNOW YOUR TOOLS",
    description:
      "Identification of Equipment & Tolls used in Agriculture-Competition",
    rules: [
      "General Rules:",
      "Eligibility: The competition is open to agricultural professionals.",
      "Registration: Participants must register  to participate in the competition.",
      "Competition Format: The competition will consist of specify format, e.g. practical identification and oral presentation",
    ],
    prizes: [
      "Certificate Of Participation to All",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mrs. Shivani Sharma - +91 7876552442",
      "Dr. Rupender Kaur - +91 7018928583",
      "Student Coordinators",
      "Gaurav - +91 7870023662",
      "Sharat - +91 7079100841",
      "Jyoti - +91 7570577455",
    ],
    location: "Tula’s Institute, E404",
    date: "29 November 2024, 2:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 3,
    image: Image58,
    slug: "planting-competition-in-a-given-area/method",
    category: "AGRI",
    name: "Planting Competition in a given area/method",
    description:
      "Planting Competition in a given area/method",
    rules: [
      "",
    ],
    prizes: [
      "",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, E404",
    date: "29 November 2024, 2:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 4,
    image: Image59,
    slug: "tulas-agri-mart",
    category: "AGRI",
    name: "Tula's Agri Mart",
    description:
      "Sale counter for seedlings, vermicompost, & pickel etc (Dept product showcase)(Stall)",
    rules: [
      "No Rules",
    ],
    prizes: [
      "No Prizes",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Cricket Ground",
    date: "29 & 30 Nov 2024",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 5,
    image: Image1,
    slug: "war-of-words",
    category: "AS",
    name: "War of Words",
    description:
      "An interactive extempore event where teams of students engage in various creative activities such as moral storytelling, cultural presentations, dumb charades, and more. This event aims to enhance critical thinking, communication skills, spontaneity, and self-confidence.",
    rules: [
      "Participants will have a time limit to speak or present on their topic.",
      "A short preparation time will be provided before the speech or activity.",
      "No external notes or materials are allowed during the presentation.",
      "Judges’ decisions will be final. In case of a tie, a second round of extempore may be conducted.",
      "Participants must adhere to the formal dress code.",
    ],
    prizes: [
      "Certificates of participation for all and Certificates and Trophies for the top 3 teams.",
    ],
    team: "4 members per team, up to 12 teams",
    fees: "Free",
    contactInfo: [
      "Mr. Deepak Chandola : +919997125399",
      "Ms. Shaista Hasan : +919997221568",
      "Ms. Shalini Binjola : +919759067727",
      "Ms. Riya Dariyal : +919557558191",
    ],
    location: "Tula’s Institute, New Seminar Hall ‘C Block’",
    date: "29 November 2024, 10:00 AM - 12:00 PM",
    note: [
      "Volunteer Students: 6 required.",
      "Audio and Video setup along with three judges are needed.",
      "Total budget: ₹1000.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 6,
    image: Image2,
    slug: "quizzard",
    category: "AS",
    name: "Quizzard",
    description:
      "Engaging engineering students in general science learning through an interactive quiz event. This event aims to promote scientific literacy, enhance critical thinking, reinforce learning, assess knowledge, and engage participants in collaborative learning.",
    rules: [
      "Competitive Quiz Event:",
      " - Format: Participants compete individually or in teams, answering science-related questions in rounds with increasing difficulty.",
      " - Objective: Test participants’ scientific knowledge in a competitive environment.",
      "Educational Event:",
      " - Format: A mix of quizzes, discussions, and interactive activities to engage and educate the audience.",
      " - Objective: Enhance scientific knowledge in an enjoyable way as part of a learning program.",
      "Fun or Interactive Quiz Event:",
      " - Format: A relaxed event with trivia questions, multimedia elements like videos or sound effects.",
      " - Objective: Foster interest in science while providing entertainment and knowledge.",
      "Requirements for candidates: Valid College ID, Completed Registration Form, Scanned Copy of ID Proof, Students must be in formals.",
      "Additional requirements: Bell, A4 sheets, pens, projector.",
    ],
    prizes: [
      "1st Prize: Trophy + Certificate",
      "2nd Prize: Trophy + Certificate",
      "3rd Prize: Trophy + Certificate",
      "Participation Certificates for all participants",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "Dr. Rachna Sharma",
      "Mr. Tikaram",
      "Ms. Gurleen Kaur",
    ],
    location: "Tula’s Institute, E404",
    date: "30 November 2024, 10:00 AM - 12:00 PM",
    note: [
      "Top 3 teams will be declared winners.",
      "Event format designed to engage and educate.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 7,
    image: Image3, 
    slug: "squid-game(its-not-so-thriller)",
    category: "AS",
    name: "SQUID GAME (ITS NOT SO THRILLER)",
    description:
      "In this UTKRIST -2024 Join the game of you want to check your luck as well as talent within you. This game is based on teams where you have to complete all the stages of funny games to become the winner.",
    rules: [
      "Only registered players will be playing.",
      "Player must be present in the venue.",
      "If caught cheating, entire team will be eliminated.",
      "Penalty will be given for misbehaving.",
    ],
    prizes: [
      " A Prize, Trophy  and certificate  to winners.",
    ],
    team: "Team Participation (max 5-6 members in team).",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Chandrashekhar - +91 9897641609",
      "Ms. Anchal Baniyal - +91 9012957611",
      "Mr. Sagar Pundir - +91 6396995243",
      "Ms. Niharika - +91 6397768855",
      "Student Coordinators",
      "Priyanshu Sinha - +91 8765574678",
      "Saurav Kumar - +916207911534",
      "Anshika - +91 9520276386"
    ],
    location: "Tula’s Institute, Field adjacent to cafeteria.",
    date: "29 November 2024, 12:00 to 2:00pm (game will start at 12pm so be on time otherwise not applicable for play)",
    note: [
      "The objective of the event is to check the presence of mind, coordination, cooperation and patience of candidate.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 8,
    image: Image4, 
    slug: "tech.-rangoli",
    category: "AS",
    name: "Tech Rangoli",
    description:
      "An artistic event where students create rangolis based on social causes to enhance their creativity and spread awareness about societal issues.",
    rules: [
      "Participants must create a rangoli based on the topics provided.",
      "Rangolis must be completed within the given time period.",
      "Participation is team-based, with up to 4 members per team.",
      "Requirements for candidates: Valid College ID, Completed Registration Form, Scanned Copy of ID Proof.",
      "Additional requirements: Rangoli colors (provided).",
    ],
    prizes: [
      "1st Prize: ₹3000",
      "2nd Prize: ₹2000",
      "3rd Prize: ₹1000",
    ],
    team: "Up to 4 members",
    fees: "Free",
    contactInfo: [
      "Mr. Chandrashekhar",
      "Ms. Anchal Baniyal",
      "Mr. Sagar Pundir",
      "Ms. Niharika: +91 6397768855",
    ],
    location: "Corridor of D Block (Top Floor)",
    date: "29 November 2024, 10:00 AM",
    note: [
      "Top 3 teams will be declared winners.",
      "Budget for colors: ₹4000. Total budget: ₹10,000.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 9,
    image: Image5,
    slug: "cadmad-plot",
    category: "CIVIL",
    name: "CadMad Plot",
    description:
      "A CAD (Computer-Aided Design) competition for civil engineering students focuses on evaluating participants' proficiency in using CAD software to design and visualize civil engineering projects. Such competitions often highlight creativity, technical skills, and problem-solving abilities in addressing real-world engineering challenges.",
    rules: [
      "Participants must be enrolled in the B. Tech Civil Engineering program.",
      "This competition is open to all undergraduate Civil Engineering students, from first to final year.",
    ],
    prizes: [
      "Top three winners will receive certificates and awards.",
      "All participants will receive a certificate of participation.",
    ],
    team: "Team of 3 Members",
    fees: "Free",
    contactInfo: [
      "Mr. Prashant Gusain:- 8527809373",
    ],
    location: "Tula’s Institute,: Lab-5 Computer Centre",
    date: "29 November 2024, 10:00 am to 12:00 pm",
    note: [
      "This competition will focus on students team spirit , their CAD knowledge &amp; their ability to manage time & amp; human resource.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 10,
    image: Image6,
    slug: "the-take-down",
    category: "CIVIL",
    name: "The Truss TakeDown",
    description: [
      "Round 1: Quiz on Bridges and Famous Structures",
      "Team Composition: Each team consists of 2 members.",
      "Question Format: Questions about bridges and famous structures will be displayed on a screen.",
      "Response Method: Teams write answers on provided sheets.",
      "Judging Criteria: Accuracy of answers; top teams advance to Round 2.",

      "Round 2: THE TRUSS TAKEDOWN HANDS ON CHALLENGE",
      "Challenge Type: Teams construct a truss-type bridge using limited materials.",
      "Materials Provided: Newspaper, glue, and rubber bands.",
      "Bridge Requirements: Must be truss-type and adhere to given dimensions.",
      "Testing Criteria:",
      " Load Test: Evaluate bridge strength underweight.",
      " Dimension Test: confirm Bridge meets specified measurements.",
      "Scoring: Up to 70 marks based on testing performance.",],
    rules: [
      "Materials Allowed:",
      "Only the provided newspapers, glue, and rubber bands may be used.",
      "No additional materials or tools (e.g., tape, string, or external supports) are permitted.",
      " Truss Structure Requirement:",
      " The bridge must be a truss design with visible triangular units, essential for strength and load distribution.",
      " Ensure that truss structure is clear and stable, with no unnecessary or decorative elements.",
      " Rolling and Folding Newspapers:",
      " Newspapers can be rolled or folded to form structural members (beams).",
      " Participants should roll tightly to increase the beam strength.",
      " Thicker beams are allowed by combining multiple layers of newspaper, as long as they stay within provided material limits.",

      " Joint Connections:",
      "Joints must be made using glue and rubber bands only.",
      "Be careful to secure each joint firmly, as weak joints can compromise bridge’s integrity.",
      " Bridge’s main joints must form sturdy triangles &amp; be securely fastened to prevent collapse under load.",
      " Dimensions:",

      " The bridge must meet the specific length, width, and height requirements provided at the start of the round.",
      " Failure to meet dimension criteria will result in disqualification.",
      " Load-Bearing Capacity:",
      "The bridge will be tested for load-bearing strength.",
      " Construct the bridge with evenly distributed weight-bearing sections to ensure that the load is effectively shared across the truss.",

      " Aesthetics and Stability:",
      " Ensure that the bridge can stand independently without additional support.",
      " Although appearance isn&#39;t scored, stable, neat construction is encouraged, as it often results in better strength and durability.",
    ],
    // prizes: [
    //   "Certificates",
    // ],
    prizes: [
      "Top three winners will receive certificates and awards.",
      "All participants will receive a certificate of participation.",
    ],
    team: "2 Members",
    fees: "Free",
    contactInfo: [
      "Mr. Archit Priyadarshi:- 9557237379",
      "Ms. Bhumika Joshi:- 7900974767"
    ],
    location: "Tula’s Institute, J 205 and J 206 (Civil Engineering Building)",
    date: "30 November 2024",
    note: [
      "Test participants’ knowledge and hands-on skills in bridge design and construction",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 11,
    image: Image7,
    slug: "act-o-master",
    category: "CIVIL",
    name: "Act O Master",
    description:
      "ACT-O-MASTERS is an engaging and interactive event organized by the Department of Civil Engineering, where teams act out industry-specific brands, taglines, or personalities through miming. Participants must guess the correct answers within a set time limit without speaking or using props. The event comprises three exciting rounds: Brand Identification, Tagline Identification, and Personality Identification, all centered around civil engineering themes. Teams of three compete to earn points, with penalties for rule violations. The team with the highest score wins, with a tiebreaker round in case of a draw. It's a fun test of creativity, teamwork, and knowledge of the industry!",
    rules: [
      "This event is opened to all students of Tula's Institute from all Departments",
      "Actors are not allowed to speak, make sounds, or mouth words.",
    ],
    prizes: [
      "Top three winners will receive certificates and awards.",
      "All participants will receive a certificate of participation.",
    ],
    team: "Team of 3 Members",
    fees: "Free",
    contactInfo: [
      "Ms. Bhumika Joshi - 7900974767",
      "Mr. Vishal Chauhan - 8449084250"
    ],
    location: "Tula’s Institute, H-BLOCK 402 ",
    date: "30 November 2024, 12:00 pm to 02:00 pm",
    note: [
      " To foster creativity, teamwork, and industry knowledge by challenging participants to guess taglines, and personalities through miming.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 12,
    image: Image14,
    slug: "estimation-and-costing-workshop",
    category: "CIVIL",
    name: "Estimation and costing workshop",
    description:
      "The Estimation and Costing Competition is a technical event designed to test participants' skills in accurately estimating material quantities, costs, and resources for a construction project. Participants will be tasked with analyzing project specifications, calculating material and labor requirements, and preparing a detailed cost analysis within the given constraints. The competition encourages logical thinking, precision, and the application of standard estimation practices, while also rewarding innovative and cost-effective approaches. This event is ideal for engineering and architecture students, providing them with practical exposure to real-world challenges in project planning and budgeting. Competitors will need to demonstrate their technical knowledge, creativity, and presentation skills within the allotted time.",
    rules: [
      "Team Formation: Teams of up to 2 students are allowed to participate.",
      "Time Limit: The time limit for the estimation work is 1 hour 30 minutes only.",
      "Estimation Task: Teams will be given a set of drawings and specifications for a construction project. They must estimate the quantities of materials required for the project.",
      "Quantity Calculation: For calculating quantities, a tolerance of ±1m3 will be considered correct.",
      "Unit Rates: Teams will be provided with a set of unit rates for materials and labor. These rates must be used for estimating the cost of the project.",
      "Submission: Teams must submit their estimation sheets and calculations within the given time limit.",
      "Judging Criteria: Teams will be judged on the accuracy of their quantity estimates and cost calculations.",
      "Penalties: Penalties will be imposed for incorrect or incomplete submissions.",
    ],
    prizes: [
      "Top three winners will receive certificates and awards.",
      "All participants will receive a certificate of participation.",
    ],
    team: "Team of upto 3 Members",
    fees: "Free",
    contactInfo: [
      "Mr. Mohit Sharma:- 7780919354",

    ],
    location: "Tula’s Institute, Lab-5, Computer Center ",
    date: "29 November 2024, 12:00 PM - 02:00 PM",
    note: [
      "To test the students' knowledge and skills in estimation and costing, and to promote healthy competition among participants.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 13,
    image: Image8,
    slug: "movie-making",
    category: "CSE",
    name: "Movie Making",
    description:
      "A short film competition by Tula’s Institute that explores the intersection of technology, creativity, and current societal issues. Participants are invited to create a 10–15-minute video on various themes, showcasing their innovation and storytelling skills.",
    rules: [
      "Eligibility: Open to students enrolled in accredited institutions with a valid ID card.",
      "Registration: Participants must register in advance with all requireddetails.",
      "Video Requirements: Duration between 10–15 minutes.",
      "Disqualification: Misbehavior, late entries, incomplete submissions, or failure to comply with terms will lead to disqualification.",
      "Judging Decision: Judges' decisions are final and binding.",
    ],
    prizes: [
      "Top participants will receive certificates, prizes or cash rewards",
    ],
    team: "Minimum 2 members and maximum 5 members",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinator-",
      "Shivali Pundir - +91 8958311081",
      "Student Coordinators-",
      "Gaurav Srivastava - +91 7270927884",
      "Prakriti Pandey - +91 9451568659",
    ],
    location: "Tula’s Institute, H-301",
    date: "30 Nov 2024, 10:00 to 12:00 PM",
    note: [
      "To inspire participants to use technology and creativity to address emerging trends, educate audiences, and create impactful narratives on significant global issues.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 14,
    image: Image10,
    slug: "mix-pix",
    category: "CSE",
    name: "MIx Pix",
    description:
      "Mix-Pix is a creative event where participants combine images to express a theme in a unique and imaginative way. This competition challenges students to showcase their artistic and conceptual thinking skills by blending multiple images into a cohesive and meaningful final artwork that aligns with a given theme Participants may use digital tools like Photoshop or Canva, or physically mix printed images to create their final piece. Each participant will present their final image along with an appropriate title and explain how it reflects the theme. Plagiarism is not allowed, if found are to be disqualified immediately. Judgement should be done on the basis of creativity, relevance to theme and presentation. Please bring your own laptops. Also, winners get trophies and winning certificate along with some surprise gifts. There’s a participation certificate for all the participants.",
    rules: [
      "Open to all students from any institution with a valid institutional ID card.",
      "Individual participation only.",
      "Participants must adhere to the time limit (e.g., 1 hour).",
      "Plagiarism is strictly prohibited and will lead to immediate disqualification.",
      "The organizer reserves the right to modify the event rules as needed.",
      "All decisions by the judges are final.",
      "Participants are required to maintain proper decorum throughout the event.",
    ],
    prizes: [
      "Top participants will receive certificates, prizes or cash rewards.",
    ],
    team: "2 Members",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Dr. Ritu Pal - +91 9650178266",
      "Student Coordinators",
      "Abhinav - +91 8868887674",
    ],
    location: "Tula’s Institute, H-301 & 302",
    date: " 29 Nov 2024, 10:00 to 12:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 15,
    image: Image12,
    slug: "crypt-your-mind",
    category: "CSE",
    name: "Crypt Your Mind",
    description:
      "Crypt Your Mind is a coding and decoding competition where participants tackle challenges involving the encryption and decryption of words, letters, or sentences using specific patterns or rules. It tests logical reasoning, cryptographic knowledge, and prolem-solving skills.",
    rules: [
      "Open to all students.",
      "Individual participation only.",
      "Participants must maintain proper decorum throughout the event.",
      "The organizer reserves the right to modify or terminate the rules as necessary.",
      "All decisions made by the judges are final",
    ],
    prizes: [
      "Top participants will receive certificates, prizes or cash rewards",
    ],
    team: "Individual participation only.",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Ms. Anita: 8755214694",
      "STUDENT COORDINATOR",
      "Rabina Kumari: 7830071140",
      "Ankita Kumar: 9430242126",
    ],
    location: "Tula’s Institute, H-401",
    date: "29 Nov 2024 12:00 PM to 2:00 PM",
    note: [
      "To enhance participants’ logical thinking, problem-solving abilities, and understanding of cryptographic techniques by engaging them in challenging coding and decoding tasks.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 16,
    image: Image9,
    slug: "quiz-battle",
    category: "CSE",
    name: "Quiz Battle",
    description:
      "Quiz Battle is an exciting competition designed to test the knowledge, speed, and accuracy of computer science students. Featuring a two-round structure, the event challenges participants to showcase their expertise in a competitive yet engaging atmosphere, ultimately crowning the most knowledgeable computer science student as the Quiz Battle Champion.",
    rules: [
      "Eligibility: Open to all computer science students. Participants must register in advance.",
      "Group Allocation: Participants will be randomly assigned to groups for Round 1.",
      "Quiz Format: Multiple-choice, true/false, and short-answer questions.",
      "Timing: Each round has a fixed time limit. Late submissions are not allowed.",
      "Scoring: Points for correct answers; no penalties for incorrect answers unless otherwise specified.",
      "Collaboration or discussion with others is strictly prohibited.",
      "Decision Authority: All decisions by the organizers and judges are final.",
      "Code of Ethics: Cheating, plagiarism, or disruptive behavior will result in disqualification.",
    ],
    prizes: [
      "Top participants will receive certificates, prizes or cash rewards.",
    ],
    team: "Individual participation only",
    fees: "Free",
    contactInfo: [
      "Faculty Cordinator",
      "Dr. Ahmad jamal - +91 7253810400",
    ],
    location: "Tula’s Institute, H-301,H-302,H-303,H-30",
    date: "30 Nov 2024 12:00 PM to 2:00 PM",
    note: [
      "To foster a spirit of competition and innovation among computer science students by encouraging them to demonstrate their knowledge, critical thinking, and problem-solving abilities.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 17,
    image: Image13,
    slug: "ui/ux-design-competition+workshop",
    category: "CSE",
    name: "Ui/Ux Design Competition+Workshop",
    description:
      "The UI/UX Design Challenge is a student-focused technical event aiming to inspire and recognize creativity in user interface (UI) and user experience (UX) design. This event is designed to provide students with a practical platform to showcase their designskills, gain hands-on experience, and receive valuable feedback from industry experts. Participants will tackle real-world design challenges, work collaboratively, and gain insights into best practices in modern design and front-end development.",
    rules: [
      "Team Size: Participation is allowed individually or in teams of up to 2 members.",
      "Time Limit: All designs must be completed and submitted within the given time frame.",
      "Originality: All submissions must be original and not copied from existing designs.",
      "Theme Adherence: Designs must strictly follow the provided theme or problem statement.",
      "Code of Conduct: Maintain professionalism; plagiarism or inappropriate content will lead to disqualification.",
      "Teams may be required to present and explain their designs to the judges.",
      "Device Compatibility: Ensure designs are responsive and functional across multiple devices.",
      "Decision Finality: The judges’ decision will be final and binding.",
    ],
    prizes: [
      "Top participants will receive certificates, prizes or cash rewards.",
    ],
    team: "Individual or teams of up to 2 participants.",
    fees: "Free",
    contactInfo: [
      "FACULTY CORDINATOR",
      "Ms. Anju: 9350051235",
      "Ms. Hema Rawat: 7055865862",
      "STUDENT CORDINATOR",
      "Yogesh: 9350051235",
    ],
    location: "Tula’s Institute, H Block (Lab 6 & Lab 7)",
    date: "29 Nov 2024,2:00 PM to 4:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 18,
    image: Image11,
    slug: "the-art-of-web+workshop",
    category: "CSE",
    name: "The Art Of Web+Workshop",
    description:
      "The Art of Web is an event consisting of a workshop and a competition on web development. Participants are encouraged to create websites, applications, and digital platforms using the latest technologies to address real-world problems.",
    rules: [
      "Eligibility: All participants must be students enrolled in an accredited institution.",
      "Original Work: Submissions must be original, and plagiarism will lead to disqualification.",
      "Intellectual Property Rights: Participants retain the rights to their designs but grant permission for promotional use by organizers.",
      "Disqualification: Misbehavior, late entries, incomplete submissions, or failure to follow terms can lead to disqualification",
    ],
    prizes: [
      "Top participants will receive certificates, prizes or cash rewards.",
    ],
    team: "Individual or Team (Maximum 4 members)",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Dr. Upendra Verma: 9793568492",
      "Mr. Rajul Ravi: 7488654900",
      "STUDENT COORDINATOR",
      "Lalit Sharma: 9991597830",
      "Abhay Jha: 6205152846",
    ],
    location: "Tula’s Institute, Lab 3",
    date: "29 November 2024, 2:00 PM to 4:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 19,
    image: Image28,
    slug: "appathon+workshop",
    category: "CA",
    name: "Appathon+Workshop", 
    description: "Appathon is a competitive event within the technical fest UTKRISHT, open to students from all engineering and computer application departments. Participants will design an app (Android, full-stack, or a web app) based on a given theme. Each team will present their app to a panel of judges through a live demonstration and a PowerPoint presentation.",
    rules: [
      "Each team may consist of 2-4 members.",
      "Open to all undergraduate and postgraduate students, preferably from Engineering and Computer Applications Departments.",
      "App types: Android/iOS mobile apps, web-based, or full-stack applications.",
      "Teams must adhere to the problem statement provided by the event organizers.",
      "Designed application must be innovative, and preferably beneficial for society.",
      "All code and design should be original or appropriately credited if using external resources.",
      "Teams must manage all resources required for the app development.",
      "Presentation should be a maximum of 10 minutes: 8 minutes for the app demonstration, 2 minutes for Q&A with the judges.",
      "Development time: 8 hours.",
      "Apps should not contain inappropriate or offensive content.",
      "Teams must submit all relevant files (code, presentation slides, etc.) before the presentation time."
    ],
    prizes: [
      "First Prize: Trophy + Cash Prize",
      "Second Prize: Trophy + Cash Prize",
      "Third Prize: Trophy + Cash Prize"
    ],
    team: "2-4 members per team, up to 20 teams",
    "fees": "Free",
    contactInfo: [
      "Dr. Shikha Tayal Aeron (Faculty Coordinator)",
      "Dr. Musheer Vaqur (Faculty Coordinator)",
      "Mr. Deepak Pathak (Faculty Coordinator)",
      "Bhaskar Kumar (Student Coordinator)",
      "Anjali Sharma (Student Coordinator - MCA 1st Year)"
    ],
    location: "LAB 3 (for Application Development) + Seminar Hall A Block (for Presentation and Judgment)",
    date: "29 November 2024 (11:00 AM - 4:00 PM) and 30 November 2024 (10:00 AM - 1:00 PM)",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 20,
    image: Image19,
    slug: "escape-room",
    category: "CA",
    name: "Escape Room",
    description: "Get ready for the ultimate test of logic, teamwork, and problem-solving skills with Escape Room! This thrilling technical event invites all students from Computer Applications and Computer Science & Engineering to step into an exciting challenge. Participants will form teams of 2-3, starting in Room 1 with computer science-themed clues, puzzles, and riddles. Once they crack Room 1, the challenge continues in Room 2, where they must solve the final clue to escape and claim victory. The first team to escape Room 2 wins the title of Escape Room champions!",
    rules: [
      "Team Composition: Each team must consist of exactly 2-3 participants. No substitutions or solo participants are allowed.",
      "Time Limit: Teams must complete each room within the specified time. Exceeding the time limit will result in disqualification.",
      "Clue Usage: Clues are to be used only as intended. Tampering with or bypassing clues will lead to disqualification.",
      "No External Assistance: Teams are not allowed to use electronic devices, books, or any other resources for solving clues.",
      "Respect the Environment: Do not damage the room or clue materials. Any damage may result in penalties or disqualification.",
      "Fair Play: Teams are not allowed to interfere with other teams or share answers. Collaboration between teams is strictly prohibited.",
      "Organizers' Decision: In case of disputes, the decision of the organizers will be final and binding.",
      "Punctuality: Teams must report on time for the event. Latecomers may not be allowed to participate."
    ],
    prizes: [
      "Trophies and Certificates for the winning team.",
      "Bonus points for exemplary teamwork."
    ],
    team: "2-3 members per team",
    fees: "Free",
    contactInfo: [
      "Ms. Vaibhavi Painuly (Faculty Coordinator) - 7895571887",

      "Mr. Anuj Singh Rajput (Faculty Coordinator) - 9319305170",
      "Shivanshu (Student Coordinator) - +91 9548805965",
      "Madhuresh Raj  (Student Coordinator) - +91 8235117157",
      "Nikhil  Kumar  (Student Coordinator) - +91 6202078451",
      "Raj Anand (Student Coordinator) - +91 7480999905",
    ],
    location: "H-301, H-302, H-303",
    date: "29 November 2024, 02:00 PM - 04:00 PM",
    note: [
      "Teams must solve each clue correctly before proceeding to the next stage.",
      "Incorrect solutions or skipped clues will result in disqualification.",
      "Teams will be observed for effective collaboration and division of tasks."
    ],

    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 21,
    image: Image22,
    slug: "code-sprint",
    category: "CA",
    name: "Code Sprint",
    description: "Code Sprint is a thrilling programming competition designed to test your knowledge, problem-solving skills, and creativity in C and C++. With rounds ranging from objective quizzes to debugging and real-world coding challenges, this event promises an exciting platform to showcase your talent and compete with the best.",
    rules: [
      "Participants must register before the event begins.",
      "Participants must carry their laptop.",
      "Ensure punctuality; latecomers may not be allowed to participate.",
      "Maintain academic integrity—any form of cheating or plagiarism will lead to disqualification.",
      "Round 1: Objective Quiz—Participants must complete the quiz within the allotted time (20–30 minutes). No external resources (books, internet, or help from others) are allowed. The top scorers will qualify for the next round.",
      "Round 2: Debugging Challenge—Buggy code will be provided; participants must debug it within the given time (30–40 minutes). Solutions must be submitted before the timer ends. Partial solutions will also be considered for scoring based on progress.",
      "Round 3: Coding Challenge—Participants will be given a problem statement to solve within 60–90 minutes. Code should be original and demonstrate efficiency and creativity. Submissions will be evaluated on correctness, efficiency, and code quality.",
      "Participants can use their preferred programming language unless specified otherwise.",
      "For coding rounds, use of the internet is restricted to documentation/reference sites (if allowed).",
      "Any software or tools used must comply with the rules of the event.",
      "Violating rules, disrupting the event, or indulging in misconduct will result in immediate disqualification."
    ],
    prizes: [
      "Certificates and Prizes for the top performers in each round."
    ],
    team: "Individual or Team Participation (Maximum 2 members per team)",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Mr. Aizaz Ahmad: +91 9758837313",
      "Ms. Hamlata: +91 7017224619",
      "STUDENT COORDINATOR",
      "Ginni Falak: +91 7061650216",
      "Ayush Mishra: +91 7061650216",
      "Lovely Bharti: ",
    ],
    location: "Lab-8 (G-Block)",
    date: "30 November 2024, 10:00 AM - 12:00 PM",
    note: [
      "Participants should arrive on time and bring their laptops.",
      "Judging will be based on accuracy, code quality, and time management."
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 22,
    image: Image34,
    slug: "turboflux",
    category: "EEE",
    name: "Turboflex",
    description: "This engaging event enhances practical skills and theoretical knowledge in electronics through interactive sessions. The theme, 'Classification & Identification of Circuit Components,' visually explains essential electronic parts. Participants will explore 'Network Analysis & Synthesis' by designing and testing breadboard circuits or using LTspice, an open-source simulation tool. The event culminates in a 'Capstone Project Making' challenge, where teams will apply their skills in soldering to create functional prototypes. With hands-on activities and problem-solving tasks, this event is an excellent opportunity for enthusiasts to deepen their understanding of electronics and circuit design.",
    rules: [
      "Round 1: Visual Quiz (Elimination round) – A quiz based on circuit components and electronics theory. The top scorers will qualify for the next round.",
      "Round 2: Network Analysis & Synthesis – Teams will design and simulate circuits using LTspice or work with breadboards to demonstrate their skills in network analysis.",
      "Round 3: Fabrication of Capstone Project – Teams will fabricate a functional prototype based on a 555 timer circuit, demonstrating their soldering and circuit design abilities.",
      "Participants must follow the guidelines and complete each round within the allotted time.",
      "Teams must consist of 2 members each. No solo participants are allowed.",
      "All participants should adhere to the rules set forth by the event organizers."
    ],
    prizes: [
      "Certificates and Prizes for the top-performing teams."
    ],
    team: "Team of 2 members",
    fees: "Free",
    contactInfo: [
      "Mr. Swati Joshi, Assistant Professor (ECE) - 9116855009",
      "Mr. Sandeep Khantwal, Assistant Professor (ECE) - 7906158365"
    ],
    location: "Tula's Institute, Electronics Lab",
    date: "29 November 2024, 10:00 AM - 3:00 PM",
    note: [
      "Teams are required to bring necessary tools for the fabrication round (e.g., soldering kits).",
      "Participants should have a basic understanding of circuit components and electronics theory."
    ],

    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 23,
    image: Image32,
    slug: "tesla-minds",
    category: "EEE",
    name: "Tesla Minds",
    description:
      "This event focuses on analyzing energy consumption and promoting sustainability at Tula’s Institute. Participants will calculate the electrical load of various campus blocks, gaining hands-on experience in energy auditing. Using their findings, they will propose practical energy-saving measures backed by precise calculations. The activity encourages participants to develop innovative solutions for reducing energy consumption, promoting efficiency, and fostering an eco-friendly campus environment. By merging analytical skills with creative thinking, this event provides a platform for students to contribute meaningfully to energy conservation while enhancing their technical and problem-solving abilities.",
    rules: [
      "Round 1- Exact Load calculation of the specific block of the Tula’s Institute.",
"Round 2- Based on the calculation of the loads of the specific block each will  suggest some energy saving proposals to conserve the energy",
    ],
    prizes: [
      "Trophies",
    ],
    team: "Team of 3 members",
    fees: "Free",
    contactInfo: [
      "Mr Rashmi Dhaundiyal , Assistant Professor (EEE) & Ms Sushma, Assistant Professor ( EEE) 9760341089, 74059037531",
    ],
    location: "Tula’s Institute, E-401",
    date: "30 November 2024, 10:00-12:00PM",
    note: [
      "Open to all engineering( ECE & EEE)  students from the institution.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 24,
    image: Image33,
    slug: "case-maze",
    category: "GSB",
    name: "Case Maze",
    description:
      "Business Decision Making: Encouraging participants to develop identify problem and making most feasible decision based on the case study. Solving Real-World Business Problems: Focused on recommending practical solutions for identified problem in general management. Collaborative Problem Solving: Promoting teamwork and diverse perspectives to enhance multi-disciplinary synergy in practical business problem solution.",
    rules: [
      "Participants will have a set time limit (of 2 hours, by 12:15 PM) to provide a solution and submit the soft copy of their presentation.",
      "Each team will present the solution of the case study as per PACADI six step decision making approach.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "5 Members",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, F 401",
    date: "29.11.24, 10:00:00am (5 hours)",
    note: [
      "To learn Business Decision Making.",
      "To solve real world business problems.",
      "To promote collaborative problem solving.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 25,
    image: Image37,
    slug: "financial-planning",
    category: "GSB",
    name: "Financial Planning",
    description:
      "The event introduces students to financial literacy by actively engaging them in financial planning activities like budgeting, saving, investing, and retirement planning.",
    rules: [
      "At the end of the 45 minutes, teams will submit their financial plan to the judges.",
      "Each team will present their financial plan to the judges and audience (if applicable).",
      "After each presentation, judges or the audience can ask the teams questions about their plan to assess their understanding of the choices they made.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, F 401 & F 402",
    date: "30 November 2024,  12:00 pm (2 hours)",
    note: [
      "To learn Financial Planning.",
      "To learn about financial literacy.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 26,
    image: Image35,
    slug: "ipl-auction",
    category: "GSB",
    name: "Ipl Auction",
    description:
      "The IPL Tula’s Auction 2024 will take place at Tula’s Institute, Dehradun. It's that time of the year again - hammer time. The IPL auction is here, a day that decides the fate of so many players. Which players will find a new home? Who will be left unsold? For which players will teams break the bank? Will there be any surprise picks? Here&#39;s everything you need to know about the 2024 IPL auction.",
    rules: [
      "Round First: 5 nos. question on GK, 5nos. on personality and 5questions on IPL Business.",
      "Round Second: Budget based auction will be there. Each team will be given a sum of fund virtually. With this fund teams need to participate in auction and have to form a team of 15 players with following conditions –",
      "A team can have maximum 4 foreign players",
      "Each team should have 6 batsmen, 3 fast bowlers, 2 spinners, 2 all rounders and 2 wicket keeper.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "2 Members",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, F 403 & ME Graphic Lab",
    date: "29 November 2024, 12:00 pm (5 hours)",
    note: [
      "To learn Auction Process.",
      "To manage budget effectively.",
      "To build a team with optimum efficiency.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 27,
    image: Image38,
    slug: "logo-reborn",
    category: "GSB",
    name: "Logo Reborn",
    description:
      " This activity aims to develop their design skills, enhance their understanding of branding, and provide an opportunity to think critically about visual communication and its impact on audience perception.",
    rules: [
      "Designs must be original and created solely for this competition.",
      "submission of pre-existing designs will lead to disqualification.",
      "Misbehavior, Late entries, incomplete submissions, or failure to comply with the terms and conditions may result in disqualification.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: " 2 Members",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, E404",
    date: "29 November 2024,  2:00 pm (2 hours)",
    note: [
      "To encourage creativity and innovation.",
      "To reimagine and redesign the logos of established companies.",
      "To enhance understanding of branding.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 28,
    image: Image36,
    slug: "ad-mad",
    category: "GSB",
    name: "Ad Mad",
    description:
      "An Ad-Mad event is a fun and creative competition, where participants create and present an advertisement for a product or service within a limited time frame. It combines marketing knowledge, creativity, and communication skills.",
    rules: [
      "Participants would be given a product theme.",
      "presentation must be an original.",
      "Misbehavior, Late entries, incomplete submissions, or failure to comply with the terms and conditions may result in disqualification.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "5 Members",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute,  F 404",
    date: "30.11.24, 10:00 am (4 hours)",
    note: [
      "To encourage creativity and innovation.",
      "To enhance understanding of branding.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 29,
    image: Image31,
    slug: "rj-hunt",
    category: "JMC",
    name: "Rj Hunt",
    description:
      "Calling all aspiring Radio Jockeys! If you have the voice, the vibe, and the vision to light up the airwaves, this is your moment to shine. Step into the spotlight and unleash your creativity, humour, and spontaneity in a thrilling hunt for the ultimate RJ at Utkrisht.",
    rules: [
      "1. It will be done in 3 rounds in the very first round participants will be given a common topic. All students will mail their voice sample on mail ID - empc@tulas.edu.in. The time limit will be 60 seconds.",

      "2.No language barrier. Participants can use  Hindi/ English Language.",

      "3. Audio File should be in MP3 Format and in the highest quality.",

      "4. Participants will use the word “Tula’s Radio” in the introduction and end of the audio.",

      "5. Style of Communication should be interactive.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Mr. Neeraj Kotiyal:- 7895653792",
      "Ms Deepika Rawat:- 7895370002"
    ],
    location: "Tula’s Institute, EMPC LAB (E-103)",
    date: "30 November 2024,  12:00pm - 2:00 pm (2-3 hours)",
    note: [
      "To allow students to showcase their skills in communication, storytelling, and live interaction.",
      "To Encourage innovative ideas, content, and approaches to presenting and hosting shows.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 30,
    image: Image15,
    slug: "digital-poster-making",
    category: "JMC",
    name: "Digital Poster Making",
    description:
      "Unleash Your Creativity! Showcase your vision for a progressive, sustainable, and innovative India in 2047 through stunning digital art. Let your creativity inspire the journey toward a brighter future through participating.",
    rules: [
      "Prior Registrations must be given.",
      "All entries to be submitted in JPEG/PNG format on email “empc@tulas.edu.in” ",
      "Provide a tagline or catchphrase related to the vision of Viksit Bharat 2047.",
      "A short description (50-100 words) explaining the key ideas behind your design.",
      "Plagiarism is strictly prohibited. Posters must be original work.",
    ],
    prizes: [
      "Top three posters will be awarded certificates and prizes.",
      "Selected posters may be showcased on social media platform of Tulas mass communication.",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "Dr. Ankit Ghildiyal:- 8650525199",
      "Dr. Neha Uniyal:- 8791406454",
    ],
    location: "Tula’s Institute, EMPC (E- 103)",
    date: "29 November 2024, 12:00pm to 2:00pm",
    note: [
      "Showcase a vision for India&#39;s progress by 2047.",
      "Promote sustainability, innovation, and inclusivity.",
      "Inspire creativity through impactful digital art.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 31,
    image: Image16,
    slug: "30-second-reel",
    category: "JMC",
    name: "Reels Rumble",
    description:
      "Unleash your creativity in Reels Rumble, a thrilling 30-second reels-making challenge at Utkrisht, your college's premier tech fest! Showcase your storytelling skills, editing finesse, and innovative ideas in this fast-paced competition. Whether it’s tech innovation, campus vibes, or a fun concept, you have just 30 seconds to impress the judges and wow the crowd.",
    rules: [
      "1. Theme: The reel must adhere to the given theme (will be given on the event day)",
      "2. Duration: The reel must not exceed 30 seconds.",
      "3. Content: ",
      "- No offensive, explicit, or plagiarized content is allowed.",
      "- Use of copyrighted material (music, video, etc.) is prohibited unless permissions are obtained.",
      "- All content must be original and created during the challenge timeline."
    ],
    prizes: [
      "Certificates",
    ],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Mr. Neeraj Kotiyal:- 7895653792",
      "Ms Deepika Rawat:- 7895370002",
    ],
    location: "Tula’s Institute, EMPC LAB E-BLOCK (103)",
    date: "30 November 2024,  10:00-12-00 AM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 32,
    image: Image17,
    slug: "laugh-out-loud:the-ultimate-riddle",
    category: "JMC",
    name: "Laugh Out Loud: The Ultimate Riddle",
    description:
      "Join us for the most exhilarating battle of intellect and humour with Laugh Out Loud, Utkrisht's premier quiz competition! Experience dynamic rounds that put your knowledge, mental agility, and sense of humour to the test as you vie for quiz mastery.",
    rules: [
      "General Rules:",
      "Team Structure: Specific team size requirements – 4 to 5 memebers.",
      "Round Format: Multiple segments featuring unique themes and guidelines.",
      "Prohibited Items: Electronic gadgets, reference materials strictly forbidden.",
      "Decorum: Participants must maintain order and stay seated.",
      "Specific Round Rules:",
      "Round 1: Silly Starters Questions asked to the teams.5 points awarded per correct response.Questions will be presented to the teams simultaneously, ensuring a structured flow to the quiz.",
      "Round 2: GEN Z Stuff Categories include Visual Media, Social Media Stars, Internet Humour, Song Recognition. 5 points per accurate answer. Questions must be answered within 10 seconds.",
      "Round 3: Tongue Twister Tournament 30-second window for tongue twister completion. 10 points awarded for successful attempts. Bonus Challenge: Visual Memory Challenge Teams document items shown in visual memory exercise. Points based on completeness and precision.",
      "Tie-Breaker Rules:",
      "Ties resolved through additional quick-fire or creative challenges (memes/tongue twisters).",
    ],
    prizes: [
      "Attractive rewards await the highest-scoring teams!",
    ],
    team: "4-5 members",
    fees: "Free",
    contactInfo: [
      "Dr. Ankit Ghildiyal:- 8650525199",
      "Dr. Neha Uniyal:- 8791406454"
    ],
    location: "Tula’s Institute, New Seminar Hall – C block",
    date: "29 November 2024, 10:00 AM",
    note: [
      "Foster collaboration, innovation, and critical thinking among contestants. Evaluate participants' expertise across entertaining subject areas. Develop a competitive yet enjoyable environment.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 33,
    image: Image29,
    slug: "design-&-fabrication-of-a-chassis-for-an-electric-kart",
    category: "ME",
    name: "Design & Fabrication of a Chassis for an Electric Kart",
    description: "A hands-on event where teams design, analyze, and fabricate a chassis for an electric kart, promoting skills in CAD, analysis, and practical manufacturing.",
    rules: [
      "Each team can have up to 5 members.",
      "Participants must wear formal attire.",
      "Teams must bring a laptop equipped with designing software.",
      "A detailed analysis report is required with given parameters.",
      "Judgment will be based on both the analysis report and the fabrication.",
      "The decision of the jury will be final."
    ],

    prizes: [
      "winners: Top 2 teams",
      "rewards: Backpack with pen and diary or a fitness band."
    ],
    team: "Maximum 10 teams(first come first serve basis)",
    fees: "Free",
    contactInfo: [
      "not avilable"
    ],
    location: "Mechanical workshop",
    date: "29 Nov 2024, 10:00 AM - 3:00 PM",
    note: [
      "not avilabe"
    ],

    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"

  },
  {
    id: 34,
    image: Image18,
    slug: "my-dream-car(auto-cad)",
    category: "ME",
    name: "My Dream Car(Auto-Cad)",
    description:
      " Get ready to showcase your creativity and innovation by designing their ultimate dream car in event of Utkrisht. Whether it's futuristic technology, sustainable features, or bold aesthetics, this competition invites students to imagine the next generation of vehicles. With a focus on originality, practicality, and environmental responsibility, participants will present their unique car concepts, blending cutting-edge design with real-world solutions. It's an opportunity to think big, challenge the norms, and be part of the future of automotive innovation!",
    rules: [
      "Participants must use AutoCAD software for designing.",
      "Designs should be original and created specifically for this competition.",
      "Participants can work individually.",
      "Submission should include a 2D/3D CAD design and a brief description.",
      "Judge’s decision will be final decision.",
      "In case of any unfair means or violation of rules, candidates will be terminated.",
    ],
    prizes: [
      "First prize",
      "Second prize",
      "Participation Certificates for all attendees.",
    ],
    team: "Individual Participation.",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute,  LAB 05 Computer lab.",
    date: "29 November 2024, 2:00pm-4pm (2 hours)",
    note: [
      "To inspire students to combine art and technology, designing sustainable, efficient, and futuristic vehicles that redefine the automotive industry, utilizing AutoCAD software. Encourage participants to create innovative and original car designs that push the boundaries of automotive technology. Promote the integration of sustainable, eco-friendly solutions in future car concepts.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },

  {
    id: 35,
    image: Image30,
    slug: "assembling-and-deassembling-of-engine-parts",
    category: "ME",
    name: "Assembling and Deassembling of Engine Parts",
    description: "A skill-based competition where teams demonstrate their efficiency in assembling and disassembling engine components, promoting teamwork, precision, and mechanical expertise.",
    rules: [
      "Each team must have a maximum of 4 members.",
      "All students must work with safety precautions and follow instructions given by the event coordinator.",
      "No random students are allowed to participate during the event.",
      "The decision of the Judges will be final."
    ],
    prizes: [
      "winners: Top 2 teams",
      "reward: Attractive prizes based on the number of registrations."
    ],
    team: "Each team must have maximum of 4 members",
    fees: "Free",
    contactInfo: [
      "not avilable"
    ],
    location: "Mechanical workshop",
    date: "29 Nov 2024, 10:00 AM - 3:00 PM",
    note: [
      "not avilable"
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 36,
    image: Image42,
    slug: "bgmi",
    category: "CENTER_STAGE",
    name: "BGMI",
    description:
      "Join us for an exciting BGMI (Battlegrounds Mobile India) tournament at the college Tech Fest “UTKRISHT”! Compete against the best teams, showcase your skills, and battle for thrilling prizes. Whether you're a seasoned player or a newcomer, this is your chance to experience the adrenaline rush of one of India's most popular mobile games. Register now and get ready to drop into action!",
    rules: [
      "Only registered players will be playi",
      "Player must be present in the venue.",
      "If caught cheating, entire team will be eliminated.",
      "Penalty will be given for misbehaving.",
      "Third party apps are restricted.",
      "Bring your own devices (no restrictions on devices).",
      "College property must not be damaged.",
      "Elimination from the match if not present in venue on time",
    ],
    prizes: [
      "Prize Pool (₹ 30,000)",
      "1. First Position (₹ 15,000)", 
      "2. Second Position (₹ 7000)", 
      "3. Third Position (₹ 4000)", 
      "4. MVP (₹ 2000)", 
      "5. Most Elimination (₹ 1000)", 
      "6. Iconic Moment (₹ 1000)",
    ],
    team: "Team Participation (max 4 members in team).",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Sharad Singh - +91 7533977999",
      "Mr. Girish Bisht - +91 7983352646",
      "Student Coordinators",
      "Deepanshu Gahatori - +91 8882256339",
      "Vikash Negi - +91 8077450635"
    ],
    location: "Tula’s Institute, Lab 1 and Lab 4.",
    date: "29 November 2024, 4:00 PM to 10:00 PM",
    note: [
      "The objective of organizing the BGMI tournament at the college Tech Fest is to provide a competitive and engaging platform for students to showcase their gaming skills, promote teamwork and strategy, and foster a sense of community through eSports. The event aims to enhance the tech festival experience by integrating popular gaming culture, offering participants a chance to compete for exciting prizes, and encouraging the development of both technical and soft skills in a fun and dynamic environment.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 37,
    image: Image43,
    slug: "cesim-business-marathon",
    category: "CENTER_STAGE",
    name: "CESIM BUSINESS MARATHON",
    description:
      "Cesim Firm is a management simulation designed to enhance participant's understanding of operating a Toy manufacturing company from a general management perspective in a competitive environment.",
    rules: [
      "Participants must be enrolled in any technical or professional course.",
      "Strategies must be developed specifically for the simulation; plagiarism will lead to disqualification.",
      "Participants retain rights to their strategies but allow organizers to use them for promotional purposes.",
      "Misbehaviour, late submissions, or failure to comply with rules may result in disqualification.",
      "All decisions by judges are final and binding.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "4 Members",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinator", 
"Anupam nautiyal - +91 9368716068",
"Bharat Bhusan - +91 9411527750",
"Prakash bhatnagar - +91 9411100086",

"Students Coordinator", 
"Ramneek kaur- +91 8168805303",
"Roma - +91 7004707079",
"Nidhi - +91 8384024646",
    ],
    location: "Tula’s Institute, Lab 8 (H Block)",
    date: "29.11.24 & 30.11.24, 10:00am (28 hours)",
    note: [
      "To improve student engagement in business decisions making skill.",
      "To learn about the complexity of global business operations.",
      "To develop one’s teamwork skills.",
      "To collaborate, think critically, and solve problems.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 38,
    image: Image44,
    slug: "live-intraday-trading",
    category: "CENTER_STAGE",
    name: "Live IntraDay Trading",
    description:
      "The Live Intraday Trading event is a dynamic, hands-on workshop designed to introduce participants to the fast-paced world of day trading in financial markets. This event will blend theory and practice, allowing attendees to engage with live market scenarios and gain real-time trading experience.",
    rules: [
      "Participants will receive access credentials for the simulation platform.",
      "Participants will trade on a simulated trading platform with live market data.",
      "Any attempts to manipulate the system or cheat will result in disqualification.",
      "Only trades executed on the competition platform during the event will be considered.",
      "Participants are prohibited from external assistance or tools during the competition.",
      "Violations of rules or use of unethical trading practices will lead to immediate disqualification.",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, LAB 03",
    date: "29.11.24, 9:30am(6hour)",
    note: [
      "To learn key trading strategies.",
      "To learn about risk management techniques, and decision-making skills.",
      "To navigate intraday market fluctuations.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 39,
    image: Image45,
    slug: "drone-racing",
    category: "CENTER_STAGE",
    name: "Drone Racing",
    description:
      "Get Ready for the Thrill of a Lifetime at Utkrisht-2024! Unleash your creativity and innovation in the electrifying world of Drone Racing! Experience the ultimate adrenaline rush as skilled pilots navigate specially designed drones through intricate courses at blazing speeds. Using advanced First-Person View (FPV) goggles, they will showcase unparalleled precision, agility, and control.",
    rules: [
      "Participants must use approved drones that comply with event specifications.",

      "Drones must meet weight, size, and power limits defined by the event (e.g., maximum weight of 250g for some classes).",

      "Only approved batteries and FPV systems are allowed.",

      "Modifications or custom builds must pass a pre-race inspection.",

      "Races can include time trials, head-to-head eliminations, or cumulative points systems.",

      "Pilots must navigate the course and complete all required laps to qualify",

      "Drones must pass through all gates and obstacles in the correct sequence.",

      "Missing or skipping obstacles results in penalties.",

      "Pilots must adhere to maximum altitude restrictions to ensure safety.",

      "Drones that fly outside the designated track area may be penalized or disqualified.",

      "Missing a gate, hitting an obstacle, or deviating from the track results in time penalties. ",
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "Individual / 8 Members ",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Mr. Sandeep Gotam - +91 8218572218",
      "STUDENT COORDINATOR",
      "Anurodh Anand - +91 9472749995",
      "Prabhat Ranjan - +91 6299390420",
    ],
    location: "Tula’s Institute, Cricket Ground",
    date: "30 Nov 2024, 12:00PM - 04:00PM",
    note: [
      "Provide Thrilling Entertainment: Offer an exciting, high-speed experience for participants and spectators. Encourage Creativity and Skills: Inspire pilots to improve their flying techniques and innovate in drone design. Promote STEM Learning: Spark interest in science, technology, engineering, and math through drones. Foster Fair Competition: Create a platform for pilots to compete and display sportsmanship. Engage Audiences: Create interactive opportunities, like FPV experiences or drone workshops. Showcase Drone Technology: Highlight the latest innovations and capabilities in drones.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 40,
    image: Image46,
    slug: "paper-presentation",
    category: "CENTER_STAGE",
    name: "Paper Presentation",
    description:
      "Paper Presentation is an intellectual event where participants present Representation of technical idea and research articles in a Paper or innovations on a specific topic related to engineering, technology, or science. Competitors will prepare and deliver a concise, informative presentation, showcasing their knowledge and analytical skills. The event encourages critical thinking and effective communication, allowing students to share insights, discuss emerging trends, and engage with peers. It's a great platform to enhance public speaking skills while presenting cutting-edge concepts in their field of study.",
    rules: [
      "Paper presentations require a considerable amount of advanced planning.",
      "You must consider the Paper’s purpose, audience, and emphasis as these factors influence content and format.",
      "A Paper is a visual presentation of your research, so be sure that the content of your Paper reflects your work.",
      "Generally, your Paper will focus on the results and implications of your research.",
      "The decision of the Judges will be the final.",
    ],
    prizes: [
      "First, second & Third prize will be announced at the same time.",
      "Participation Certificates for all attendees.",
    ],
    team: "Individual or Team(max 3 members). ",
    fees: "Free",
    contactInfo: [
      "Faculty cordinator",
      "Mr. Rahul Kumar - 9627528372",
      "Dr Subhash - 9919740549",
      "Student Coordinator",
      "Arif Javed - 6206755966",
      "Dipanshu Raj - 7857872631",
    ],
    location: "Tula’s Institute, New Seminar Hall, Block C",
    date: "30-11-2024, 12:00 Pm-2 Pm (2 hours)",
    note: [
      "To bring the researchers together with their innovative idea and share their research interest. Researchers summarize their work, present it in a visually-appealing printed Paper, and briefly explain their research to an audience. To explores the comprehensive innovative idea and research. To encourage the audience to comment on and ask questions about your work. Researchers learn the powerful techniques of communicating research. ",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 41,
    image: Image47,
    slug: "project-exhibition",
    category: "CENTER_STAGE",
    name: "Project Exhibition",
    description:
      "Dive into the forefront of technological advancements with projects that explore the latest in artificial intelligence and sustainable energy. Delve into groundbreaking AI algorithms that enhance machine learning, natural language processing, and predictive analytics, driving technological progress across various fields. Simultaneously, discover state-of-the-art solutions in renewable energy, energy storage, and smart grids, showcasing the breakthroughs in solar, wind, and bioenergy technologies that are paving the way for a cleaner, more sustainable future. OBJECTIVE: The primary objective of this event is to showcase and celebrate groundbreaking advancements in technology that are shaping the future. By bringing together cutting-edge projects such as Advanced AI Algorithms and Sustainable Energy Solutions",
    rules: [
      "All team must consist of 1 to 4 members", 
"Minimum one member should be present at the stall while representing",
    ],
    prizes: [
      "10,000 Rupees",
    ],
    team: "Individual / 4 Members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Rakesh Kumar - +91 95483 76402",
    ],
    location: "Tula’s Institute, Cricket Ground",
    date: "29-NOV-2024, 10:00am (6 hours)",
    note: [
      " The primary objective of this event is to showcase and celebrate groundbreaking advancements in technology that are shaping the future. By bringing together cutting-edge projects such as Advanced AI Algorithms and Sustainable Energy Solutions",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 42,
    image: Image48,
    slug: "ar/vr-event",
    category: "CENTER_STAGE",
    name: "AR/VR Event",
    description:
      "Immerse yourself in the revolutionary worlds of Augmented Reality (AR) and Virtual Reality (VR) at Utkrisht! This thrilling event invites you to unleash your creativity and innovation by designing extraordinary virtual experiences and enhancing real-world interactions. Discover the limitless possibilities of technology and redefine how we see and engage with the world. Get ready to shape the future!",
    rules: [
      "Respect for Fair Play: All participants must adhere to fair play principles, ensuring that the game is played in the spirit of friendly competition and sportsmanship.",
      "No Use of External Help: Participants must play the game independently without assistance from outside sources, including online guides, tutorials, or other individuals during the event.",
    ],
    prizes: [
      "No Prizes",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "FACULTY CORDINATORS",
      "Mr. Kshitij Jain- +91 8375052135",
      "Mr. Prakhar Goel- +91 9994758886",
      "STUDENT CORDINATORS",
      "Rohit Mishra - +91 9123155216",
      "Sachin - +91 8382893485",
    ],
    location: "Tula’s Institute, E404",
    date: "",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 43,
    image: Image49,
    slug: "3d-printing-competition",
    category: "CENTER_STAGE",
    name: "3D Printing Competition",
    description:
      "Unleash your creativity and innovation in the world of 3D printing! This exciting competition challenges students to design a functional or artistic 3D model within a limited time frame. Selected designs will be printed and judged to find the ultimate winner. Dive into the future of design and manufacturing with this thrilling event.",
    rules: [
      "Participants must design their models using provided or approved software (e.g., Onshape, TinkerCAD)." , 
      "The design must align with the provided theme and be completed within the given time.", 
      "Judges will evaluate the designs before selecting models for printing.", 
      "Printed objects will determine the final winner.",
      "Participants must adhere to the competition timeline and submit designs on time.",
    ],
    prizes: [
      "Exciting prizes and rewards for the winners.",
    ],
    team: "Individual or Team",
    fees: "Free",
    contactInfo: [
      "Dr. Sunil Semwal:- +91 90275 47705",
    ],
    location: "Tula's Institute, Lab-3",
    date: "29 November 2024, 10:00 Am - 12:00 PM",
    note: [
      "Design a 3D model based on the provided theme within the given time. Demonstrate creativity, functionality, and practicality in your design. Compete to create the best 3D-printed model.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 44,
    image: Image50,
    slug: "industry360",
    category: "CENTER_STAGE",
    name: "Industry 360",
    description:
      "Industry 360 is an event that provides participants with a comprehensive      overview of a specific industry, offering insights into its trends, challenges, and innovations. It involves discussions, presentations, and expert panels to bridge the gap between academia and industry. The event aims to enhance participants' understanding of real-world applications and career opportunities. It serves as a platform for learning, networking, and exploring emerging industry developments. Event Type – Power Point Presentation about Any Industry.",
    rules: [
      "Each team has to represent one Company (based on latest technologies.). Presentation should contain following aspects like about the Industry, Mission & Vision, what we do how we do (Machines and latest technologies used), Relevant Data.",
      "Students must be in formals.",
      "Presentation through Microsoft Power Point.",
      "Time allotted for presentation will be 10 minutes per team.",
      "No limit on number of slides within time limit.",
      "Decision of the judge will be final.",
      "In case of any unfair means or violation of rules, candidates/team will be terminated.",
    ],
    prizes: [
      "First prize",
      "Second prize",
      "Participation Certificates for all attendees.",
    ],
    team: "Maximum 10 Teams (Each team constitute of Minimum 2 members and Maximum of 3 members)",
    fees: "Free",
    contactInfo: [
      "",
    ],
    location: "Tula’s Institute, New Seminar Hall, Block C",
    date: "30-11-2024, 2:00 PM - 4:00 PM (2 hours)",
    note: [
      "Provide students with a comprehensive understanding of industry trends and innovations. Bridge the gap between academic knowledge and real-world industry applications. Create networking opportunities with industry experts and professionals for career development.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 45,
    image: Image51,
    slug: "junkyard",
    category: "CENTER_STAGE",
    name: "JUNKYARD",
    description:
      "'Kabad-se-Jugaad' or 'Junkyard': An activity to unleash your creativity and innovation in the most thrilling and eco-friendly way. This exciting competition challenges students to transform everyday junk into something extraordinary and useful, all within a limited time frame. Teams have to collect waste materials like cardboard, plastic, batteries, cells, e-waste (broken chargers and headphones) etc. and have to create something useful within 3 hours. The purpose of the activity is to raise awareness about reducing waste and the importance of recycling. It's a concept that promotes the idea of reusing and repurposing discarded items to create something new.",
    rules: [
      "All teams must have at least 2 members and no more than 4 members. Prior Registrations must be given.",
      "Do not open and view the clues prior to the announcement that the hunt has started.",
      "Participants can use waste materials like bottles, newspapers, old utensils, or jute material or any second hand items that otherwise would be thrown away.",
      "The required waste material and stationery like scissor, thread, etc. should be collected & brought by the participants in the provided 3 hours. ",
      "The material would be rejected if not found to be a waste product or second hand item. ",
      "No ready or semi-finished model or matter would be accepted from participant in competition. ",
      
    ],
    prizes: [
      "Exciting prizes and rewards",
    ],
    team: "2-4 Members",
    fees: "Free",
    contactInfo: [
      "Dr. Neelima Bangwal :- +91 87556 52441",
      "Mr Sanoj Mandal :- +91 96348 95599"
    ],
    location: "Tula’s Institute, Ground Adjacent to Exam Cell (Block E)  ",
    date: "29-Nov-2024, 10:00am (4 hours)",
    note: [
      "Utilize the provided junk materials to create a functional or artistic item within the given time limit.",
      "Demonstrate creativity, practicality, and sustainability in your creation."
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 46,
    image: Image39,
    slug: "sumo-suit-wrestling",
    category: "SPECIAL_EVENTS",
    name: "Sumo Suit Wrestling",
    description: "Get ready to rumble in our Sumo Suit Wrestling challenge! Wear our inflatable sumo suits and battle it out with your friends to emerge victorious.",
    rules: [
      "Individual or team participation (2 members)",
      "Participants must register prior to the event.",
      "The event will be held on a first-come, first-served basis.",
    ],
    prizes: ["Certificates for the winners"],
    team: "Individual / 2 Members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Ms Swati yadav: +91 90123 54794",
      "Ms Nandani dhayani: 9758062230",
      "STUDENT COORDINATOR",
      "Mazhar: 7033775404",
      "Priyanka: 8755354296",
    ],
    location: "Football Ground",
    date: "29-Nov-2024, 12:00 PM – 2:00 PM",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 47,
    image: Image23,
    slug: "minute-to-win-it",
    category: "FUN_ACTIVITIES",
    name: "Minute to Win it",
    description: "Get ready to test your speed, agility, and teamwork in our Minute to Win It challenge! Complete a series of tasks within a minute to win exciting prizes.",
    rules: [
      "No Rules"
    ],
    prizes: ["chocolates"],
    team: "Individual ",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Ms Niharika prashar: +91 6397768855",
      "Ms Monalsha chand: 7454846677",
      "STUDENT COORDINATOR",
      "Shivansh: 8218053948",
      "Astha: 8085426013",
    ],
    location: "Cricket Ground ",
    date: "30-Nov-2024, 2:00pm – 4:00 pm",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 48,
    image: Image25,
    slug: "tug-of-war",
    category: "FUN_ACTIVITIES",
    name: "Tug of War",
    description: "Get ready to test your strength and teamwork in the ultimate Tug of War challenge! Gather your friends and form a team to battle it against other teams. Who will emerge victorious?",
    rules: [
      "Each team must have 8 members.",
      "Teams must register prior to the event."
    ],
    prizes: ["Certificates for the winning team"],
    team: "8 Members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Mr Satish kumar: +917895351268",
      "Mr Devesh sharma: +91 98089 80782",
      "STUDENT COORDINATOR",
      "Kshitij: 8789650512",
      "Aditya: 94308 47778",
    ],
    location: "Near Cafetria",
    date: "30-Nov-2024, 2:00pm – 4:00 pm",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 49,
    image: Image27,
    slug: "flash-mob",
    category: "FUN_ACTIVITIES",
    name: "Flash Mob",
    description: "",
    rules: [
      ""
    ],
    prizes: [""],
    team: "",
    fees: "Free",
    contactInfo: [
      ""
    ],
    location: "C-203",
    date: "29 Nov 2024, 10:00 AM - 3:00 PM",
    note: [
      "",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 50,
    image: Image52,
    slug: "tech-wall(e-waste-collection-&-re-usage)",
    category: "FUN_ACTIVITIES",
    name: "Tech Wall(E-Waste Collection & Re-Usage)",
    description: "",
    rules: [
      ""
    ],
    prizes: [""],
    team: "",
    fees: "Free",
    contactInfo: [
      ""
    ],
    location: "C-203",
    date: "29 Nov 2024, 10:00 AM - 3:00 PM",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 51,
    image: Image53,
    slug: "electromaze-eee",
    category: "FUN_ACTIVITIES",
    name: "ELECTROMAZE-EEE",
    description: "",
    rules: [
      ""
    ],
    prizes: [""],
    team: "",
    fees: "Free",
    contactInfo: [
      ""
    ],
    location: "C-203",
    date: "29 Nov 2024, 10:00 AM - 3:00 PM",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 52,
    image: Image20,
    slug: "slow-cycle-race-ash",
    category: "FUN_ACTIVITIES",
    name: "Slow Cycle Race-ASH",
    description: "Get ready to test your balance and patience in the most thrilling and entertaining event of Utkrisht – Slow Bicycle Race! This unique competition challenges participants to ride their bicycles as slowly as possible, keeping their balance intact while racing against the clock (in reverse!). The last one to cross the finish line without falling wins! It's not just a race; it's a test of focus, skill, and sheer determination, all in the spirit of fun and friendly competition.",
    rules: [
      "Each participant must ride their bicycle as slowly as possible without stopping or losing balance.",
"The rider who crosses the finish line last without touching the ground or veering out of the designated track wins.",
"Use of feet to support balance will lead to disqualification.",
"Judges’ decisions will be final.",
    ],
    prizes:[ "Top 3 winners will be awarded attractive prizes.",],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Faculty coordinator",
"Dr. Ashish Mishra - +91 8630621465",
"Mr. Shubham Joshi - +91 8533909801",

"Student coordinator",
"Abhay Kumar Jha - +91 6205152846", 
"Anil Kumar - +91 6201075515",
    ],
    location: "Tula’s Institute, Basketball Ground",
    date: "30 Nov 2024, 12:00 pm -2:00 pm",
    note: [
      "To test participants' balance, control, and patience while riding a bicycle. To provide an engaging and enjoyable experience for participants and spectators. To promote healthy competition and teamwork"
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 53,
    image: Image21,
    slug: "matki-mania",
    category: "FUN_ACTIVITIES",
    name: "MATKI MANIA",
    description: "Matki Mania is an exciting team-based game where two players work together to hit a hanging matki (pot) using a stick. One player is blindfolded, while the other provides verbal instructions to guide their partner toward the target. The challenge lies in communication and trust, as the blindfolded player must rely entirely on their teammate’s directions to succeed.",
    rules: [
      "team participation (2 members)",
      "Participants must register prior to the event.",
      "The event will be held on a first-come, first-served basis."
    ],
    prizes: ["Certificates for the winners"],
    team: "2 Members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Mr Sandeep gautam: +91 82185 72218",
      "Mr Abhishek sharma: +91 79835 25287",
      "STUDENT COORDINATOR",
      "Saket: 78375 75064",
      "Bittu: 9153032349",
    ],
    location: "Near Vibgyor Office",
    date: "29-Nov-2024, 12:00 PM - 2:00 PM",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 54,
    image: Image26,
    slug: "face-painting",
    category: "FUN_ACTIVITIES",
    name: "FACE PAINTING",
    description: "The Face Painting Competition invites teams of two to showcase their artistic skills. Each team consists of a painter and a model. The painter transforms their model’s face into a work of art, demonstrating creativity and technique. Participants will compete for the title of best face artist, with judges evaluating based on design, execution, and originality.",
    rules: [
      "Team participation (2 members)",
      "Participants must register prior to the event.",
      "The event will be held on a first-come, first-served basis.",
    ],
    prizes: ["Gift hampers for the best face paint designs"],
    team: "2 Members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Ms Achal baniya: +91 90129 57611",
      "Ms Riya Dariyal: +91 82799 64438",
      "STUDENT COORDINATOR",
      "Ridhima: 7983177977",
      "Devashish: 98706 14378",
    ],
    location: "C Block 301",
    date: "30-Nov-2024, 2:00 pm - 4:00 pm",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 55,
    image: Image24,
    slug: "treasure-hunt",
    category: "FUN_ACTIVITIES",
    name: "Treasure Hunt",
    description: "Embark on an exciting adventure and test your problem-solving skills in our Treasure Hunt challenge! Decipher clues, solve puzzles, and work with your team to find the hidden treasure.",
    rules: [
      "Each team must have max/min 5 members.",
      "Teams must register before the event.",
      "The event will be held on a first-come, first-served basis."
    ],
    prizes: ["Gift Hamper and certificate "],
    team: "Each team must have max/min 5 members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Mr Emmanuel gabriel",
      "STUDENT COORDINATOR",
      "Vansh: 9956171775",
      "Arzoo: 7668361610",
    ],
    location: "Staircase near cricket ground",
    date: "29-Nov-2024, 2:00 pm - 4:00 pm",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 56,
    image: Image54,
    slug: "echoes-of-talent",
    category: "FUN_ACTIVITIES",
    name: "Echoes of Talen",
    description: 'Echoes of Talent" is a vibrant showcase of creativity and passion, where students dazzle with their skills in dancing, singing, acting, stand-up comedy, and more. Its a celebration of talent, fun, and individuality, offering everyone a chance to shine and inspire. Step into the spotlight and let your talent echo! 🎭🎤✨',
    rules: [
      "Participation is open to all students of the college",
      "Each performance must be completed within the allotted time (e.g., 3-5 minutes).",
      "Any props or equipment needed must be arranged by the participants and set up within a specified time.",
      "Participants must maintain discipline and respect fellow performers and organizers. Misbehavior can lead to disqualification."
    ],
    prizes: [
      "Exciting prizes and rewards"
    ],
    team: "",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Ms. Divya: 9870642705",
      "Mr. Siddharth: 7060195695",
      "STUDENT COORDINATOR",
      "Ranjan Shukla: 8409523171",
      "Ashif: 8271073357",
    ],
    location: "Stage in front of Tendulkar pavilion",
    date: "30-Nov-2024, 10:00 am onwards",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 57,
    image: Image55,
    slug: "act-sketch-showdown",
    category: "FUN_ACTIVITIES",
    name: "Act and Sketch Showdown",
    description: "A fun and engaging game where players act out prompts like movie titles, songs, or books without speaking, while their teammates try to guess. Designed to encourage creativity and teamwork, this event provides a lively and interactive experience in a college setting.",
    rules: [
      "Teams must consist of 2–4 players and include at least one female member.",
      "No speaking or sounds are allowed; only gestures can be used for acting.",
      "Each team will have 1–2 minutes to guess the prompt.",
      "Prompts will include categories like movies, songs, and books, with a primary focus on movies.",
      "Actors may use common hints like pointing to oneself for 'I' or to an ear for 'sounds like.'",
      "Teams earn points for correct guesses within the time limit."
    ],
    prizes: ["Attractive prizes for the winners."],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "not avilable"
    ],
    location: "C-203",
    date: "29 Nov 2024, 10:00 AM - 3:00 PM",
    note: [
      ""
      
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 58,
    image: Image40,
    slug: "paint-ball",
    category: "SPECIAL_EVENTS",
    name: "Paint Ball",
    description:
      "Gear up for the ultimate event of Utkristh—Paintball Game, where strategy meets adrenaline! Navigate a thrilling battlefield, dodge opponents, and fire vibrant shots to claim victory. The battlefield awaits—will you dominate or be outplayed?",
    rules: [
      "Team Size: Individual players are welcome! If you don’t have a team, don’t worry—we’ll assign you to one.",
      "Registration Fee:",
      "Pre-registration: ₹150 per player (20 balls each player).",
      "On-spot registration: ₹200 per player (10 balls each player).",
      "Prior registration is preferred to ensure a smooth experience; on-spot registration will be available only if slots are left from prior registration.",
      "Players will be eliminated once they run out of paintballs—so aim carefully and keep moving!",
      "Play fair, respect others, and enjoy the ultimate paintball adventure.",
    ],
    prizes: [
      "Amazing rewards and certificates for the most skilled players.",
    ],
    team: "Individual or Team",
    fees: "₹150 per player",
    contactInfo: [
      "Faculty Coordinator:",

      "Kshitij Jain: 8375052135",
      "Prakhar Goel: 7302119735",
      "Aashiya Khatoon: 8949038983",
      "Student Coordinator:",

      "Riwaj Karki: 7992241097",
      "Rishanshu Tripathi: 6206066630",
      "Deepak Kumar: 7492045312",
    ],
    location: "Tula’s Institute, Cricket Ground",
    date: "29th & 30th November 2024, 10:00 AM – 4:00 PM (6 hours each day)",
    note: [
      "Engage in a thrilling paintball experience, where your goal is to outsmart and outlast your opponents. Whether you’re solo or part of a team, showcase your tactical skills, teamwork, and agility to come out on top.",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 59,
    image: Image41,
    slug: "laser-fight",
    category: "SPECIAL_EVENTS",
    name: "Laser Fight",
    description:
      "Step into the future with Utkristh—Laser Fight Game, an electrifying battle of precision, speed, and strategy! Armed with high-tech laser guns, players will engage in an intense combat simulation, where every move counts. Outsmart your opponents, dodge their shots, and use your laser weapons to dominate the battlefield. Will you emerge victorious in this high-tech showdown?",
    rules: [
      "Team Size: Individual players are welcome! Don’t have a team? No problem—we’ll team you up!",
      "Registration Fee:",
      "Pre-registration: ₹150 per player.",
      "On-spot registration: ₹200 per player.",
      "Prior registration is preferred; on-spot registration will be available only if slots are left from prior registration.",
      "All necessary equipment will be provided—just bring your skills and excitement!",
      "Players are eliminated once they’re hit by a laser—keep moving, stay alert, and aim for the win!",
      "Play fair, respect your fellow competitors, and enjoy the futuristic adventure.",
    ],
    prizes: [
      "Amazing rewards and certificates for the most skilled players.",
    ],
    team: "Individual or Team",
    fees: "₹150 per player",
    contactInfo: [
      "Faculty Coordinator:",

      "Kshitij Jain: 8375052135",
      "Prakhar Goel: 7302119735",
      "Student Coordinator:",

      "Gaurav Shukla: 7505678294",
      "Piyush Lingwal: 7534810655",
      "Irshad: 8250607916",
    ],
    location: "Tula’s Institute, Computer Center Seminar Hall",
    date: "29th & 30th November 2024, 10:00 AM – 4:00 PM (6 hours each day)",
    note: [
      "Enter the high-stakes world of laser combat! Whether you're a solo fighter or part of a team, your goal is to outmaneuver and outshoot your opponents in a thrilling and action-packed laser fight. Show off your quick reflexes, tactical thinking, and teamwork to secure your victory!",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform",
  },
  {
    id: 60,
    image: Image39,
    slug: "sumo-suit-wrestling",
    category: "SPECIAL_EVENTS",
    name: "Sumo Suit Wrestling",
    description: "Get ready to rumble in our Sumo Suit Wrestling challenge! Wear our inflatable sumo suits and battle it out with your friends to emerge victorious.",
    rules: [
      "Individual or team participation (2 members)",
      "Participants must register prior to the event.",
      "The event will be held on a first-come, first-served basis.",
    ],
    prizes: ["Certificates for the winners"],
    team: "Individual / 2 Members",
    fees: "Free",
    contactInfo: [
      "FACULTY COORDINATOR",
      "Ms Swati yadav: +91 90123 54794",
      "Ms Nandani dhayani: 9758062230",
      "STUDENT COORDINATOR",
      "Mazhar: 7033775404",
      "Priyanka: 8755354296",
    ],
    location: "Football Ground",
    date: "29-Nov-2024, 12:00 PM – 2:00 PM",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 61,
    image: Image59,
    slug: "act-it-out-act-&-sketch-showdown",
    category: "FUN_ACTIVITIES",
    name: "ACT IT OUT - Act & Sketch Showdown",
    description: "Act and Sketch Showdown is a popular party game where players act out the title of a movie, book, show, or other prompts without speaking, while their teammates try to guess it. It's entertaining, encouraging creative, and is often played in teams. The goal is to make this a fun, interactive event that challenges students' creativity and movie knowledge in a friendly college setting.",
    rules: [
      "The actor cannot use words or sounds. Only gestures are allowed. In gesture, actor is not allowed to convey word/letter in air.",
      "The challenge could be from various categories like movies, songs, or books. Most commonly, movies are used.",
    ],
    prizes: ["Exciting prizes and rewards"],
    team: "Duo / 4 Members",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mohd Mursleen - +91 9690447707",
      "Mrs. Payal Manwal - +91 7409119072",

      "Student Coordinators",
      "Devashish - +91 9870614378",
      "Sneha Arora - +91 8630145078",
    ],
    location: "Tula’s Institute, H Block (H401 – H402)",
    date: "30-Nov-2024, 12:00am to 03:00pm ",
    note: [
      "To foster creativity, teamwork, and quick thinking in a fun, interactive way. The game usually involves participants working together to convey a word, phrase, or concept through acting or sketching without using words or explicit verbal clues."
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 62,
    image: Image59,
    slug: "chess",
    category: "FUN_ACTIVITIES",
    name: "chess",
    description: "Join us for an exciting day of strategic battles and intellectual mastery at the UTKRISHT, Open Chess Championship Whether you're a seasoned grandmaster, an emerging prodigy, or a casual player looking to challenge your skills, this event offers a platform for all enthusiasts of the timeless game of chess.",
    rules: [
      "Will be announced",
    ],
    prizes: ["Exciting prizes and rewards"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Dinesh Negi: 8077940715",
      "Student Coordinators",
      "Nainsee Singh: 7017582758",
      "Aditya Kumar: 9798862155",
    ],
    location: "Tulas cricket ground (Near Aromas Mess)",
    date: "29 & 30 Nov 2024",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 63,
    image: Image59,
    slug: "carrom",
    category: "FUN_ACTIVITIES",
    name: "carrom",
    description: "Get ready to unleash your creativity and innovation in the most thrilling and eco-friendly event of Utkrisht Junkyard! This exciting competition challenges students to transform everyday junk into something extraordinary and useful, all within a limited time frame.",
    rules: [
      "Will be announced",
    ],
    prizes: ["Exciting prizes and rewards"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Dinesh Negi: 8077940715",
      "Student Coordinators",
      "Ashish Kumar: 9508686186",
      "Adarsh Kumar: 9576511956",
    ],
    location: "Tulas cricket ground (Near Aromas Mess)",
    date: "29 & 30 Nov 2024, 11:00AM (6 hour)",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 64,
    image: Image59,
    slug: "musical-chair",
    category: "FUN_ACTIVITIES",
    name: "Musical Chair",
    description: "Musical Chairs is a popular party game that combines music, fun, and a bit of competitive spirit. It’s typically played in a group and involves both music and movement, where players must quickly find a seat when the music stops.",
    rules: [
      "1. Setup of Chairs: ",
      "Arrange chairs in a circle, with one fewer chair than the number of players. For example, if there are 8 players, set up 7 chairs.",
      "2. Start of the Game",
      "Players stand in a circle around the chairs.",
      "The game host or DJ controls the music, which plays while players walk, dance, or move around the chairs",
      "3. Music and Movement",
      "Players must walk around the chairs as the music plays. They can dance or move in any way they choose but must stay within the circle of chairs.",
      "4. Stopping the Music",
      "The host stops the music at random intervals. When the music stops, all players must quickly find and sit in a chair.",
      "5. Elimination",
      "The player who is left standing without a chair when the music stops is eliminated from the game.",
      "After each round, one chair is removed, and the game continues.",
      "6. No Pushing or Shoving",
      "Players are not allowed to push, shove, or physically harm others to claim a seat. The game should be played fairly and respectfully.",
      "7. Winning",
      "The game continues until only one player remains, sitting in the last chair. This player is declared the winner.",
      "8. Fair Play",
      "The host or referee ensures that everyone plays by the rules, including no cheating or pushing, and maintains a fun and safe environment.",
      "Do not open and view the clues prior to the announcement that the hunt has started.",
    ],
    prizes: ["Exciting prizes and rewards"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Dinesh Negi: 8077940715",
      "Student Coordinators",
      "Madhuresh Raj: 8235117157",
      "Nikhil Kumar: 9304707643",
    ],
    location: "Tula's Ground (Near Mess)",
    date: "29-30 Nov-2024, 11:00am (6 hours)",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 65,
    image: Image59,
    slug: "arm-wrestling",
    category: "FUN_ACTIVITIES",
    name: "Arm Wrestling ",
    description: "Arm wrestling is a competitive sport in which two participants attempt to pin each other's arm down onto a surface, usually a table, using only their arms and strength. The sport typically involves two individuals facing each other with their elbows resting on a flat surface, like an arm wrestling table, and their hands gripping each other’s in a standardized position.",
    rules: [
      "Both competitors must keep their elbows on the table throughout the match.",
      "If a competitor lifts their elbow off the table or moves it outside the designated zone, it is considered an elbow foul. After one foul, the referee typically issues a warning. If the foul is repeated, the opponent may win the match by default.",
      "The match begins with a neutral grip, where both competitors' hands are positioned palm-to-palm and in a straight line, without any preemptive movement.",
      
    ],
    prizes: ["Exciting prizes and rewards"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Dinesh Negi: 8077940715",
      "Student Coordinators",
      "Naman Bajpai: 9855634848",
      "Himanshu Pandey: 8533839002",
    ],
    location: "Tula's Ground (Near Mess)",
    date: "29-30 Nov-2024, 11:00am (6 hours)",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },
  {
    id: 66,
    image: Image59,
    slug: "lemon-race",
    category: "FUN_ACTIVITIES",
    name: "LEMON RACE",
    description: "Get ready to unleash your creativity and innovation in the most thrilling and eco-friendly event of Utkrisht – Junkyard! This exciting competition challenges students to transform everyday junk into something extraordinary and useful, all within a limited time frame.",
    rules: [
      "Hold the lemon with the spoon.",
      "No touching the lemon with hands.",
      "The match begins with a neutral grip, where both competitors' hands are positioned palm-to-palm and in a straight line, without any preemptive movement.",
      
    ],
    prizes: ["Exciting prizes and rewards"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Faculty Coordinators",
      "Mr. Dinesh Negi: 8077940715",
      "Student Coordinators",
      "Aakash Rajput: 9548266215",
      "Subham Kumar: 7461098496",
    ],
    location: "Tula's Ground (Near Mess)",
    date: "29-30 Nov-2024, 11:00am (6 hours)",
    note: [
      ""
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfl6qna7K_qiQm0sxvOFM17CU4umAD6r_wfEZZPBVA7Kef_ug/viewform"
  },







];
import React from "react";
import classes from "./EventSection.module.css";
import Button from "../common/Button/Button";
import event from "./banner.png";

const EventSection = () => {
  return (
    <>
      <section id="about" className={classes.aboutSec}>
        <div className={classes.about}>
          <div className={classes.details}>
            {/* <h3 className={classes.heading}>About</h3> */}
            <h2 className={classes.heading1}>EVENTS</h2>
            <p className={classes.para}>
            UTKRISHT embodies the essence of our college tech fest, offering a platform for individuals to exceed their limits and transform their innovative ideas into reality. The fest features a wide array of technical events and competitions aimed at showcasing participants' talents and skills while promoting creativity, teamwork, and problem-solving abilities. With its emphasis on technology and innovation, Utkrisht truly lives up to its name, acting as a catalyst for turning the extraordinary into the achievable.
            </p>

            {/* <p className={classes.para}>
            Spectacular talent stretches boundaries to inspire the participating
            students and the rapt audience alike. A gigantic range of events
            from advanced robotics challenges to entrepreneurship hunts
            promising to zap up the cranial indices of participants. Skill,
            genius and knowledge trading infused with a handful of fun, and a
            titanic prize money sum of above half a million rupees to make Karma
            one of the most unique tech carnivals of the country.
          </p> */}
            <div className={classes.btn}>
              <Button link="/events" label="Explore" />
            </div>
          </div>

          <div className={classes.composition}>
            <img className={classes.images} src={event} alt="about" />
          </div>
        </div>
      </section>
    </>
  );
};

export default EventSection;

import React, { useEffect, useState, useRef } from "react";
import classes from "./Hero.module.css";
import { Link } from "react-router-dom";
import Button from "../common/Button/Button";
import text_img from "../../assets/logo-transparent.png";
// import text_img from "./text.svg";

const Hero = () => {
  const [countDays, setDays] = useState(false);
  const [countHours, setHours] = useState(false);
  const [countMinutes, setMinutes] = useState(false);
  const [countSeconds, setSeconds] = useState(false);

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date("November 29 2024 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance / 1000 / 60) % 60);
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        //
        clearInterval(interval);
      } else {
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <section id="home" className={classes.hero}>
      <div className={classes.herobox}>
        <div className={classes.headerbox}>
          {/* <h1 className={classes.heading}>Sambhav</h1> */}
          <img src={text_img} className={classes.text_img} alt="text_img" />

          <h4 className={classes.caption}>Tech Dreams, Realized Together!</h4>
          <p className={classes.date}>November 29-30, 2024</p>
          <div className={classes.heroBtns}>
            <Link
              className={classes.anchorBtn}
              // target="_blank"
              rel="noopener noreferrer"
              to="/events"
            >
              <Button
                link="/events"
                label="Explore Our Events"
                className={classes.btn}
              ></Button>
            </Link>
            <Link
              className={classes.specialanchorBtn}
              // target="_blank"
              rel="noopener noreferrer"
              to="/special-events"
            >
              <button className={classes.specialcategorybtn_selected}>Special Events</button>
            </Link>

          </div>
        </div>

        <div className={classes.countdownbox}>
          <div className={classes.countdown}>
            <p>{countDays}</p>
            <p>
              <small>days</small>
            </p>
          </div>

          <span className={classes.column}>:</span>

          <div className={classes.countdown}>
            <p>{countHours}</p>
            <p>hours</p>
          </div>

          <span className={classes.column}>:</span>

          <div className={classes.countdown}>
            <p>{countMinutes}</p>
            <p>min</p>
          </div>

          <span className={classes.column}>:</span>
          <div className={classes.countdown}>
            <p>{countSeconds}</p>
            <p>sec</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

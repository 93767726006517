import React, { useEffect, useState } from "react";
import EventCard from "../common/EventCard/EventCard";
import classes from "./MainEvents.module.css";
// import SwupOverlayTheme from "@swup/overlay-theme";
// import Swup from "swup";
import { eventsData } from "../../assets/eventsData";
import ReactGA from "react-ga";

const MainEvents = () => {
  const [current, setCurrent] = useState(10);
  // const current = 1;

  function toggle(id) {
    if (current === id) {
      return setCurrent(null);
    }
    return setCurrent(id);
  }


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <>
      <div className={classes.events_section}>
        <h1 className={classes.heading}>Events</h1>
        {/* <h3 className={classes.subheading}>AS</h3> */}
        <div className={classes.categorymenu_cont}>
          <button className={`${current === 12 ? classes.specialcategorybtn_selected : classes.specialcategorybtn}`} onClick={() => toggle(12)}>SPECIAL EVENTS</button>
          <button className={`${current === 10 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(10)}>CENTER STAGE</button>
          <button className={`${current === 4 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(4)}>CSE</button>
          <button className={`${current === 1 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(1)}>AGRICULTURE</button>
          <button className={`${current === 2 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(2)}>AS</button>
          <button className={`${current === 3 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(3)}>CIVIL</button>
          <button className={`${current === 5 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(5)}>CA</button>
          <button className={`${current === 6 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(6)}>EEE / ECE</button>
          <button className={`${current === 7 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(7)}>GSB</button>
          <button className={`${current === 8 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(8)}>JMC</button>
          <button className={`${current === 9 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(9)}>ME</button>
          <button className={`${current === 11 ? classes.categorybtn_selected : classes.categorybtn}`} onClick={() => toggle(11)}>FUN ACTIVITIES</button>
        </div>

        {/* SPECIAL Section  */}
        <div className={`${current === 12 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "SPECIAL_EVENTS") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* AGRICULTURE Section  */}
        <div className={`${current === 1 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "AGRI") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>
        {/* AS Section  */}
        <div className={`${current === 2 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "AS") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* CIVIL Section  */}
        <div className={`${current === 3 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "CIVIL") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* CSE Section  */}
        <div className={`${current === 4 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "CSE") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* CA Section  */}
        <div className={`${current === 5 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "CA") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* EEE / ECE Section  */}
        <div className={`${current === 6 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "EEE") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* GSB Section  */}
        <div className={`${current === 7 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "GSB") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* JMC Section  */}
        <div className={`${current === 8 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "JMC") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* ME Section  */}
        <div className={`${current === 9 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "ME") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* CENTER_STAGE Section  */}
        <div className={`${current === 10 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "CENTER_STAGE") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

        {/* FUN ACTIVITIES Section  */}
        <div className={`${current === 11 ? classes.show_category : classes.hide_category}`}>
          {eventsData.map((eventData, i) => {
            const { category } = eventData;
            if (category == "FUN_ACTIVITIES") {
              return <EventCard eventData={eventData} key={i} />;
            }
          })}
        </div>

      </div>
    </>
  );
};

export default MainEvents;
